import styles from "./Company.module.scss";

import { FC } from "react";

import Phone from "assets/icons/call@2x.png";
import Home from "assets/icons/home@2x.png";
import Market from "assets/icons/storefront@2x.png";
import Placeholder from "assets/images/image@2x.png";
import { baseUrl } from "credentials/url";
import Image from "lib/components/containers/Image/Image";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";

interface CompanyProps {
  company: string;
  address: string;
  phone: string;
}

export const Company: FC<CompanyProps> = ({ company, address, phone }) => {
  return (
    <Wrapper className={styles.company} direction="column">
      <Image
        height={172}
        alt="?"
        src={`${baseUrl}/assets/images/image@2x.png`}
        className={styles.image}
      />
      <div className={styles.content}>
        <h4 className={styles.companyName}>{company}</h4>
        <Wrapper className={styles.item}>
          <Image
            width={24}
            height={24}
            alt="?"
            src={Home}
            className={styles.icon}
          />
          <p className={styles.itemText}>{address}</p>
        </Wrapper>
        <Wrapper className={styles.item}>
          <Image
            width={24}
            height={24}
            alt="?"
            src={Phone}
            className={styles.icon}
          />
          <p className={styles.itemText}>{phone}</p>
        </Wrapper>

        <Wrapper className={styles.item}>
          <Image
            width={24}
            height={24}
            alt="?"
            src={Market}
            className={styles.icon}
          />
          <p className={styles.itemText}>Get Directions</p>
        </Wrapper>
      </div>
    </Wrapper>
  );
};
