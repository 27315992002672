import { Client } from "api/index";
import "credentials/api-key";

export const getArticles = async (articlesOnly?: boolean, topic?: string) => {
  try {
    const {
      content: articles,
      companyBio,
      heroSettings,
    } = await Client.StreamPluginClientAPI.streamedArticles(
      //@ts-ignore

      articlesOnly ? ["Articles"] : undefined,
      topic ? [topic] : undefined,
      undefined,
      undefined,
      1,
      10,
    );
    for (const article of articles) {
      article.publishedOn = new Date(article.publishedOn).toLocaleString(
        "en-US",
        {
          month: "short",
          day: "2-digit",
          year: "numeric",
        },
      );
    }

    return { articles, companyBio, heroSettings };
  } catch (e: any) {
    console.log(e);
    throw new Error("Not found");
  }
};
export const getServices = async () => {
  try {
    const { services } = await Client.StreamPluginClientAPI.services();

    return services;
  } catch (e: any) {
    console.log(e.data.detail);
    throw new Error("Not found");
  }
};
export const getTypes = async () => {
  try {
    const { contentTypes } = await Client.StreamPluginClientAPI.contentTypes();

    return contentTypes;
  } catch (e: any) {
    console.log(e.data.detail);
    throw new Error("Not found");
  }
};
export const formatType = (type: any): string => {
  return type
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char: any) => char.toUpperCase()); // Capitalize first letter of each word
};

export const getIndustry = async () => {
  try {
    const { industryCategory } =
      await Client.StreamPluginClientAPI.industry_category();
    return industryCategory;
  } catch (e: any) {
    console.log(e.data.detail);
    return {};
  }
};

export const getArticleContent = async (category: any, title: any) => {
  try {
    const {
      articleDetails,
      relatedArticles,
      h1,
      facebookImageUrl,
      twitterImageUrl,
    } = await Client.StreamPluginClientAPI.articleContent(
      category as string,
      title as string,
    );

    for (const article of relatedArticles) {
      article.publishedOn = new Date(article.publishedOn).toLocaleString(
        "en-US",
        {
          month: "short",
          day: "2-digit",
          year: "numeric",
        },
      );
    }
    articleDetails.type = "Article";
    articleDetails.h1 = h1;
    articleDetails.facebookImageUrl = facebookImageUrl;
    articleDetails.twitterImageUrl = twitterImageUrl;

    return { articleDetails, relatedArticles };
  } catch (e) {
    throw new Error("Not found");
  }
};

export const getSpecialtyGuidePreview = async (title: any) => {
  try {
    const guide = await Client.StreamPluginClientAPI.guidePreview(
      title as string,
    );
    guide.content = guide.contentSnippet;

    guide.type = "Specialty Guide";

    return guide;
  } catch (error) {
    throw new Error("Not found");
  }
};

export const getEventPreview = async (title: any) => {
  try {
    const event = await Client.StreamPluginClientAPI.eventPreview(
      title as string,
    );

    return event;
  } catch (e) {
    throw new Error("Not found");
  }
};

export const getWebinarPreview = async (title: any) => {
  try {
    const webinar = await Client.StreamPluginClientAPI.webinarPreview(
      title as string,
    );

    webinar.type = "Webinar";

    return webinar;
  } catch (e) {
    throw new Error("Not found");
  }
};

export const getReportPreview = async (title: any) => {
  try {
    const { reportDetails } = await Client.StreamPluginClientAPI.reportPreview(
      title as string,
    );

    reportDetails.type = "Report";

    return reportDetails;
  } catch (error) {
    throw new Error("Not found");
  }
};
