import styles from "./Medium.module.scss";

import { FC } from "react";

import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import { Article } from "pages/Home/types/article";
import Image from "lib/components/containers/Image/Image";
import Placeholder from "assets/images/image@2x.png"
import Event from "assets/icons/event.svg";
import Star from "assets/icons/kid_star.svg";
import Guide from "assets/icons/specialty_guide.svg";
import { baseUrl } from "credentials/url";

interface MediumProps {
  article: Article;
}

export const Medium: FC<MediumProps> = ({
  article: {
    description,
    industryCategory,
    type,
    publishedOn,
    title,
    imageUrl,
  },
}) => {
  return (
    <Container className={styles.medium} direction="column">
      {type == "specialty_guides" && (
        <Wrapper className={styles.type}>
          <Image width={16} height={16} alt="?" src={Guide} /> Specialty guide
        </Wrapper>
      )}
      {type == "events" && (
        <Wrapper className={styles.type}>
          <Image width={16} height={16} alt="?" src={Event} /> Event
        </Wrapper>
      )}
      {type == "webinars" && (
        <Wrapper className={styles.type}>
          <Image width={16} height={16} alt="?" src={Star} /> Webinar
        </Wrapper>
      )}
      {type == "reports" && (
        <Wrapper className={styles.type}>
          <Image width={16} height={16} alt="?" src={Star} /> Report
        </Wrapper>
      )}
      <Container className={styles.imageContainer}>
        {imageUrl ? (
          <Image
           
            alt="?"
            src={imageUrl}
            className={styles.image}
            
          />
        ) : (
          <Image
       
            alt="?"
            src={`${baseUrl}/assets/images/image@2x.png`}
            
            className={styles.image}
          />
        )}
      </Container>
      <Box direction="column" className={styles.content}>
        {industryCategory != "N/A" && (
          <p className={styles.topic}>{industryCategory}</p>
        )}
        <h4 className={styles.title}>{title}</h4>
        {description != "N/A" && (
          <p className={styles.description}>{description}</p>
        )}
        <Box alignment="left" gap={10}>
          <p className={styles.date}> {publishedOn}</p>
          <div className={styles.circle}></div>
          <p className={styles.readMore}>Read More</p>
        </Box>
      </Box>
    </Container>
  );
};
