import styles from "./Type.module.scss";

import { FC } from "react";

import Checkbox from "lib/components/clickables/Checkbox";
import Box from "lib/components/containers/Box/Box";

interface TypeProps {
  typeList: any[];
  checkedTypes: string[];
  setCheckedTypes: React.Dispatch<any>;
}

export const Type: FC<TypeProps> = ({
  typeList,
  checkedTypes,
  setCheckedTypes,
}) => {
  const handleCheckboxChange = (event: any) => {
    const { checked, value } = event.target;
    setCheckedTypes((prev: any) =>
      checked ? [...prev, value] : prev.filter((v: any) => v !== value)
    );
  };
  const n = typeList.length; // Total number of elements
  const rows = n / 2; // Number of rows
  const cols = 2; // Number of columns

  const typeRes = [];
  for (let i = 0; i < rows; i++) {
    typeRes.push(typeList.slice(i * cols, i * cols + cols));
  }

  return (
    <Box direction="row" alignment="top-left" width={'100%'} >
      {typeRes.map((type, index) => (
        <Box className={styles.checkbox} key={`${type[0]} - ${type[1]}`} alignment="top-left" direction="column">
          <Checkbox
            text={type[0]}
            handleChange={handleCheckboxChange}
            checked={checkedTypes.includes(type[0])}
            sx={{ marginBottom: "16px" }}
            key={type[0]}
          />
          {type[1] && (
            <Checkbox
              text={type[1]}
              handleChange={handleCheckboxChange}
              checked={checkedTypes.includes(type[1])}
              key={type[1]}
            
            />
          )}
        </Box>
      ))}{
      /* <Box direction="column" gap={24} className={styles.checkbox}>
        <Checkbox
          text="Articles"
          handleChange={handleCheckboxChange}
          checked={checkedTypes.includes("Articles")}
        />
        <Checkbox
          text="Special Offers"
          checked={checkedTypes.includes("Special Offers")}
          handleChange={handleCheckboxChange}
        />
      </Box>
      <Box direction="column" gap={24} className={styles.checkbox}>
        <Checkbox
          text="Reports"
          handleChange={handleCheckboxChange}
          checked={checkedTypes.includes("Reports")}
        />
        <Checkbox
          text="White Pages"
          handleChange={handleCheckboxChange}
          checked={checkedTypes.includes("White Pages")}
        />
      </Box>
      <Box direction="column" gap={24} className={styles.checkbox}>
        <Checkbox
          text="Specialty Guides"
          handleChange={handleCheckboxChange}
          checked={checkedTypes.includes("Specialty Guides")}
        />
        <Checkbox
          text="Events"
          handleChange={handleCheckboxChange}
          checked={checkedTypes.includes("Events")}
        />
      </Box>
      <Box direction="column" gap={24} className={styles.checkbox}>
        <Checkbox
          text="Webinars"
          handleChange={handleCheckboxChange}
          checked={checkedTypes.includes("Webinars")}
        />
        <Checkbox
          text="Social Platforms"
          handleChange={handleCheckboxChange}
          checked={checkedTypes.includes("Social Platforms")}
        />
      </Box> */}
    </Box>
  );
};
