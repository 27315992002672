import styles from "./Button.module.scss";

import React, { FC, KeyboardEventHandler, ReactNode, SVGProps } from "react";

interface ButtonProps {
  children?: ReactNode;
  Icon?: JSX.Element;
  sx?: React.CSSProperties;
  className?: string;
  type?: "outlined" | "primary" | "secondary" | "none" | "sign-up";
  color?: "neutral-400" | "primary-400" | "white" | "outline" | "none";
  padding?: "big" | "small" | "none";
  size?: "s" | "m" | "a" | "buttonm";
  disabled?: boolean;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const buttonMap = {
  outlined: styles.outlined,
  "sign-up": styles.signup,
  "generate-article": styles.generateArticle,
  "outline-hollow": styles.outlineHollow,
  primary: styles.primary,
  delete: styles.delete,
  outlinedPrimary: styles.outlinedPrimary,
  google: styles.google,
  secondary: styles.secondary,
  none: undefined,
};

const colorMap = {
  "neutral-400": styles.colorNeutral,
  "primary-400": styles.colorPrimary,
  outline: styles.colorOutline,
  white: styles.white,
  none: styles.none,
};

const paddingMap = {
  small: styles.paddingSmall,
  big: styles.paddingBig,
  none: styles.paddingNone,
};

const sizeMap = {
  s: styles.s,
  a: "",
  m: styles.m,
  buttonm: styles.buttonm,
};

const Button: FC<ButtonProps> = ({
  children,
  Icon,
  sx,
  className = "",
  type = "primary",
  color = "primary-400",
  padding = "small",
  size = "a",
  disabled = false,
  onClick,
  onKeyDown,
}) => {
  const isDisabled = disabled ? `${styles.disabled}` : ``;

  const classRecord = `${className} ${buttonMap[type]} ${styles.button} ${colorMap[color]} ${paddingMap[padding]} ${isDisabled} ${sizeMap[size]}`;

  return (
    <button
      className={classRecord}
      style={sx}
      onClick={disabled ? () => {} : onClick}
      onKeyDown={(e: any) => {
        onKeyDown && onKeyDown(e);
      }}
    >
      {Icon && Icon}
      {children}
    </button>
  );
};

export default Button;
