import styles from "./Home.module.scss";

import { FC, JSX, Suspense, useEffect, useRef, useState } from "react";

import { Date_range, Topic } from "api/api";
import { Client } from "api/index";
import Placeholder from "assets/images/section-hero@2x.jpg";
import { baseUrl } from "credentials/url";
import { useDebounce } from "hooks/useDebounce";
import Button from "lib/components/clickables/Button/Button";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import LoadingSpinner from "lib/components/other/LoadingSpinner/LoadingSpinner";
import { useSkipFirstRender } from "lib/hooks/useSkipFirstRender";
import { useApiKeyStore } from "store/apiKeyStore";
import { usePaginationStore } from "store/pagination";
import { useSearchStore } from "store/search";

import CardArticle from "./components/Card/Article";
import ArticleInfo from "./components/Card/Company";
import CardCompany from "./components/Card/Company";
import CardRequest from "./components/Card/Request";
import CardServices from "./components/Card/Services";
import Filter from "./components/Filter";
import Welcome from "./components/Welcome";
import { Article } from "./types/article";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface HomeProps {
  articles: any;
  heroSettings: {
    h1: string;
    heroImageUrl: string;
    introText: string;
    welcomeText: string;
  };
  services: string[];
  industry: string;
  typeList?: any;
  type?: string;
  topic?: string;
  url?: string;
}

const typeMap = {
  specialty_guides: "Specialty Guides",
  events: "Events",
  articles: "Articles",
  reports: "Reports",
  webinars: "Webinars",
  specialty_offers: "Specialty Offers",
  white_pages: "White Pages",
  social_platforms: "Social Platforms",
};

const Home: FC<HomeProps> = ({
  articles,
  heroSettings,
  services,
  industry,
  typeList,
  type,
  topic,
  url = "",
}) => {
  // const [articles, setArticles] = useState<any>([]);
  const { search } = useSearchStore();
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const [loading, setLoading] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);

  const [articleList, setArticleList] = useState(articles);

  const [types, setTypes] = useState<any>([]);
  const [topics, setTopics] = useState<any>([]);
  const [calendar, setCalendar] = useState<Value>();
  let indexRef = 0;

  const debouncedSearch = useDebounce(search, 500);
  useEffect(() => {
    setArticleList(articles);
  }, [articles]);

  useSkipFirstRender(() => {
    const getArticles = async () => {
      setLoading(true);
      const {
        content: articles,
        totalCount,
        totalReturned,
        page,
      } = await Client.StreamPluginClientAPI.streamedArticles(
        type ? [type] : types.length > 0 ? types : undefined,
        topic ? [topic] : topics.length > 0 ? topics : undefined,
        //@ts-ignore
        Array.isArray(calendar) && calendar.length > 0
          ? [calendar[0]?.toISOString() || "", calendar[1]?.toISOString() || ""]
          : undefined,
        search || undefined,
        paginationNumber,
        10,
      );
      for (const article of articles) {
        article.publishedOn = new Date(article.publishedOn).toLocaleString(
          "en-US",
          {
            month: "short",
            day: "2-digit",
            year: "numeric",
          },
        );
      }

      setArticleList((prev: any) => [...prev, ...articles]);
      if (10 * paginationNumber >= totalCount) setShowLoadMore(false);
      setPaginationNumber(page);
      setLoading(false);
      setLoadMore(false);
    };
    if (loadMore) getArticles();
    else setLoadMore(false);
  }, [loadMore]);

  useSkipFirstRender(() => {
    const getArticles = async () => {
      setLoadingArticles(true);
      const {
        content: articles,
        totalCount,
        totalReturned,
        page,
      } = await Client.StreamPluginClientAPI.streamedArticles(
        type ? [type] : types.length > 0 ? types : undefined,
        topic ? [topic] : topics.length > 0 ? topics : undefined,
        //@ts-ignore
        Array.isArray(calendar) && calendar.length > 0
          ? [calendar[0]?.toISOString() || "", calendar[1]?.toISOString() || ""]
          : undefined,
        search || undefined,
        paginationNumber,
        10,
      );
      for (const article of articles) {
        article.publishedOn = new Date(article.publishedOn).toLocaleString(
          "en-US",
          {
            month: "short",
            day: "2-digit",
            year: "numeric",
          },
        );
      }

      setArticleList(articles);

      if (10 * paginationNumber >= totalCount) setShowLoadMore(false);
      else setShowLoadMore(true);
      setPaginationNumber(1);
      setLoadingArticles(false);
    };
    getArticles();
  }, [types, topics, calendar, debouncedSearch]);

  useEffect(() => {
    const getArticles = async () => {
      const { totalCount, totalReturned } =
        await Client.StreamPluginClientAPI.streamedArticles(
          type ? [type] : types.length > 0 ? types : undefined,
          topic ? [topic] : topics.length > 0 ? topics : undefined,
          //@ts-ignore
          Array.isArray(calendar) && calendar.length > 0
            ? [
                calendar[0]?.toISOString() || "",
                calendar[1]?.toISOString() || "",
              ]
            : undefined,
          search || undefined,
          paginationNumber,
          10,
        );
      if (10 * paginationNumber >= totalCount) setShowLoadMore(false);
    };
    getArticles();
  }, []);

  // useEffect(() => {
  //   const storedPosition = getScrollPosition();
  //   console.log(storedPosition);
  //   if (storedPosition) {
  //     window.scrollTo(storedPosition.x, storedPosition.y);
  //   }
  // }, []);

  // useEffect(() => {
  //   const saveScrollPosition = () => {
  //     const scrollPosition = { x: window.scrollX, y: window.scrollY };
  //     saveScrollPositionToStorage(scrollPosition);
  //   };

  //   // Attach the event listener
  //   window.addEventListener("scroll", saveScrollPosition);

  //   // Cleanup on component unmount
  //   return () => {
  //     window.removeEventListener("scroll", saveScrollPosition);
  //   };
  // }, []);

  // // Restore the scroll position when the component mounts

  // Initial load

  return (
    <>
      <Container
        alignment="center"
        className={styles.blogBanner}
        sx={{
          backgroundImage: `url('${heroSettings.heroImageUrl || baseUrl + Placeholder}')`,
        }}
      >
        <h1 className={styles.blogBannerHeading}>{heroSettings.h1}</h1>
      </Container>
      <Container
        className={styles.articleSection}
        direction="column"
        alignment="center"
      >
        <Welcome heroSettings={heroSettings} />
        <Filter
          setTypes={setTypes}
          types={types}
          setTopics={setTopics}
          topics={topics}
          setCalendar={setCalendar}
          calendar={calendar}
          topicList={services}
          typeList={typeList}
          topic={topic}
          type={type}
        />
      </Container>
      <Container className={styles.articlesContainer} direction="column">
        {loadingArticles ? (
          <>
            <LoadingSpinner />
            <Box height={"800px"}></Box>
          </>
        ) : articleList.length > 0 ? (
          <div className={styles.articles}>
            {articleList

              .map((article: any, index: number) => {
                const { id } = article;
                const res: JSX.Element[] = [];

                if (indexRef == 0) {
                  res.push(
                    <CardCompany
                      key={`company-${id}`}
                      company={article.companyName}
                      address={article.clientAddress}
                      phone={article.clientPhoneNumber}
                    />,
                  );
                  indexRef++;
                }
                if (indexRef == 5) {
                  res.push(<CardRequest key={`request-${id}`} />);
                  indexRef++;
                }
                if (indexRef == 6) {
                  res.push(
                    <CardServices
                      services={services}
                      industry={industry}
                      key={`services-${id}`}
                    />,
                  );
                  indexRef++;
                }

                const modIndex = indexRef++ % 12;
                if (modIndex != 4 && modIndex != 11) {
                  res.push(
                    <CardArticle article={article} key={`article-${id}`} />,
                  );
                } else if (modIndex === 4) {
                  res.push(
                    <CardArticle
                      size="medium"
                      article={article}
                      key={`article-medium-${id}`}
                    />,
                  );
                } else if (modIndex === 11) {
                  res.push(
                    <CardArticle
                      size="large"
                      article={article}
                      key={`article-large-${id}`}
                    />,
                  );
                }

                return res;
              })
              .flat()}
          </div>
        ) : (
          <Box height={"800px"} alignment="top">
            <h2> No articles to show</h2>:
          </Box>
        )}

        {loading ? (
          <LoadingSpinner />
        ) : (
          showLoadMore &&
          !loadingArticles && (
            <Box alignment="center" className={styles.loadMore}>
              <Button
                type="outlined"
                onClick={() => {
                  setPaginationNumber(paginationNumber + 1);
                  setLoadMore(true);
                }}
              >
                Load More
              </Button>
            </Box>
          )
        )}
      </Container>
    </>
  );
};

export default Home;
