import styles from "./Authorization.module.scss";

import { FC, forwardRef, useEffect, useRef, useState } from "react";

import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import useOutsideClick from "lib/hooks/useOutsideClick";
import { isValidEmail } from "lib/utils/isValidEmail";
import { toast } from "react-toastify";
import { useAuthStore } from "store/auth";

import CheckEmail from "./CheckEmail/CheckEmail";
import CreatePassword from "./CreatePassword/CreatePassword";
import Login from "./Login/Login";
import Signup from "./Signup/Signup";

export interface AuthorizationProps {}

const Authorization: FC<AuthorizationProps> = forwardRef(({}, ref) => {
  const { state, setState } = useAuthStore();
  const [email, setEmail] = useState("");
  const authorizaitonRef = useRef<any>(null);
  const { authorized, showAuthorization, setShowAuthorization } =
    useAuthStore();

  useOutsideClick(authorizaitonRef, () => {
    setShowAuthorization(false);
  });


  useEffect(() => {
    const originalOverflow = document.body.style.overflow;

    if (!showAuthorization) document.body.style.overflow = "visible";
    else document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [showAuthorization]);

  return (
    showAuthorization && (
      <>
        <Container className={styles.authorization} direction="column">
          <Box className={styles.space}></Box>

          <Box className={styles.shadow} ref={authorizaitonRef}>
            {state == "signup" && (
              <Signup
                changeState={() => {
                  setState("login");
                }}
                handleContinue={() => {
                  if (isValidEmail(email)) setState("create-password");
                  else {
                    toast.error("Please enter a valid email address");
                  }
                }}
                email={email}
                setEmail={setEmail}
              />
            )}
            {state == "login" && (
              <Login email={email} changeState={() => setState("signup")} />
            )}
            {state == "create-password" && (
              <CreatePassword
                email={email}
                handleEdit={() => {
                  setState("signup");
                }}
              />
            )}
            {state == "check-email" && <CheckEmail />}
          </Box>
        </Container>
      </>
    )
  );
});
export default Authorization;
