import styles from "./Places.module.scss";

import { FC } from "react";

import { baseUrl } from "credentials/url";
import Button from "lib/components/clickables/Button/Button";
import Box from "lib/components/containers/Box/Box";
import Image from "lib/components/containers/Image/Image";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";

interface PlacesProps {}

export const Places: FC<PlacesProps> = ({}) => {
  return (
    <Wrapper className={styles.places} direction="column">
      <Image
        alt="?"
        src={`${baseUrl}/assets/images/places@2x.png`}
        className={styles.image}
      />
      <Box className={styles.content} direction="column">
        <h3 className={styles.heading}>Places We Service</h3>
        <p className={styles.text}>
          Wherever you are, we can deliver the results you want.
        </p>

        <Button type="outlined">View Services Areas</Button>
      </Box>
    </Wrapper>
  );
};
