import styles from "./Login.module.scss";

import { FC, useState } from "react";

import { UserLogIn } from "api/api";
import { Client } from "api/index";
import Button from "lib/components/clickables/Button/Button";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Input from "lib/components/forms/Input/Input";
import { useForm } from "lib/hooks/useForm";
import { useApiKeyStore } from "store/apiKeyStore";
import { useAuthStore } from "store/auth";

export interface LoginProps {
  email: string;

  changeState: () => void;
}

const Login: FC<LoginProps> = ({ email, changeState }) => {
  const { form, handleChange } = useForm({
    email: email,
    password: "",
  });
  const { apiKey } = useApiKeyStore();
  const { setAuthorized, setShowAuthorization } = useAuthStore();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container className={styles.login} direction="column" alignment="center">
      <p className={styles.heading}>Log In</p>
      <Box className={styles.form} direction="column">
        <Wrapper className={styles.inputWrapper}>
          <label className={styles.label}>Email Address</label>
          <Input
            design="normal"
            type="email"
            value={form.email}
            onChange={(e) => {
              handleChange(e, "email");
            }}
          />
        </Wrapper>
        <Wrapper className={styles.inputWrapper}>
          <label className={styles.label}>Password</label>
          <Box className={styles.inputContainer}>
            <Input
              design="normal"
              type="password"
              value={form.password}
              onChange={(e) => {
                handleChange(e, "password");
              }}
              className={styles.input}
            />
            <div
              onClick={togglePasswordVisibility}
              className={styles.inputHoverButton}
            >
              {showPassword ? "Hide" : "Show"}
            </div>
          </Box>
          <div className={styles.forgotPassword}>Forgot Password?</div>
        </Wrapper>
        <Button
          type="primary"
          onClick={async () => {
            const { accessToken } = await Client.StreamPluginClientAPI.login(
              new UserLogIn({ email: form.email, password: form.password })
            );
            localStorage.setItem("token", accessToken);
            setAuthorized(true);
            setShowAuthorization(false);
          }}
        >
          Log In
        </Button>
        <p className={styles.noAccount}>
          No account yet?{" "}
          <span className={styles.signUp} onClick={changeState}>
            Sign Up
          </span>
        </p>
      </Box>
    </Container>
  );
};

export default Login;
