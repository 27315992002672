import styles from "./Info.module.scss";

import { FC } from "react";

import Container from "lib/components/containers/Container/Container";

import DetailsInfoCompany from "components/pages/Home/components/Card/Company";
import DetailsInfoPlaces from "./Places";
import DetailsInfoServices from "./Services";
import DetailsInfoSubscribe from "./Subscribe";

interface InfoProps {
  topic?: string;
  services: string[];
  company: string;
  address: string;
  phone: string;
}

export const Info: FC<InfoProps> = ({
  topic,
  services,
  company,
  address,
  phone,
}) => {
  return (
    <Container className={styles.info} alignment="top" direction="column">
      <DetailsInfoCompany company={company} address={address} phone={phone} />
      <DetailsInfoServices services={services} industry={topic || ""} />
      <DetailsInfoPlaces />
      <DetailsInfoSubscribe topic={topic} />
    </Container>
  );
};
