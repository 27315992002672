import { ChangeEvent, useState } from "react";

export const useForm = <Type>(initialValue: Type) => {
  const [form, setForm] = useState<Type>(initialValue);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof Type,

    customHandleChange: (newForm: Type) => void = () => {},
  ) => {
    const newForm: Type = { ...form };
    (newForm[field] as string) = e.target.value;
    customHandleChange(newForm);
    setForm(newForm);
  };

  const isFormEmpty = () => {
    for (const key in form) {
      if (form[key] === "") return true;
    }
    return false;
  };

  const emptyForm = () => {
    const emptyForm: Type = { ...form };
    for (const key in form) {
      (emptyForm[key] as string) = "";
    }
    setForm(emptyForm);
  };

  return { form, handleChange, isFormEmpty, emptyForm, setForm };
};

//field: keyof Type
