import { CSSProperties, FC } from "react";

interface ImageProps {
  src?: string;
  alt?: string;

  className?: string | "none";
  padding?: string | "0";
  borderRadius?: string | "0";
  sx?: CSSProperties;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  onClick?: () => void;
}

const Image: FC<ImageProps> = ({
  src,
  alt = "?",
  className = "none",
  padding = "0",
  borderRadius = "0",
  sx,
  width = "none",
  height = "none",
  minWidth = "none",
  minHeight = "none",
  maxWidth = "none",
  maxHeight = "none",
  onClick,
}) => {
  const classRecord = `${className !== "none" ? className : ""}`;
  return (
    <img
      alt={alt}
      className={classRecord}
      src={src}
      style={{
        display: "flex",
        padding: padding !== "0" ? padding : undefined,
        borderRadius: borderRadius !== "0" ? borderRadius : undefined,

        width: width !== "none" ? width : undefined,
        height: height !== "none" ? height : undefined,
        minWidth: minWidth !== "none" ? minWidth : undefined,
        minHeight: minHeight !== "none" ? minHeight : undefined,
        maxWidth: maxWidth !== "none" ? maxWidth : undefined,
        maxHeight: maxHeight !== "none" ? maxHeight : undefined,

        ...sx,
      }}
      onClick={onClick}
    />
  );
};

export default Image;
