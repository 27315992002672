import styles from "./Subscribe.module.scss";

import { FC } from "react";

import { Body_subscribe_to_newsletter_api_v1_stream_plugin_subscribe_post } from "api/api";
import { Client } from "api/index";
import Send from "assets/icons/send.svg";
import { apiKey } from "credentials/api-key";
import Box from "lib/components/containers/Box/Box";
import Image from "lib/components/containers/Image/Image";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Input from "lib/components/forms/Input/Input";
import { useForm } from "lib/hooks/useForm";
import { isValidEmail } from "lib/utils/isValidEmail";
import { toast } from "react-toastify";

interface SubscribeProps {
  topic?: string;
}

export const Subscribe: FC<SubscribeProps> = ({ topic }) => {
  const { form, handleChange } = useForm({ email: "" });
  const sendEmail = async () => {
    if (isValidEmail(form.email))
      await Client.StreamPluginClientAPI.subscribe(
    
        new Body_subscribe_to_newsletter_api_v1_stream_plugin_subscribe_post({
          email: form.email,
        }),
      );
    else {
      toast.error("Please insert valid email");
    }
  };
  return (
    <Wrapper className={styles.subscribe} direction="column">
      <Box className={styles.content} direction="column">
        <h3 className={styles.heading}>
          Like our articles{topic && ` on ${topic}`}?
        </h3>
        <p className={styles.text}>
          Subscribe for a curated weekly list of our latest articles! Enter your
          email below; we promise to keep your information private and never
          send unwanted spam.
        </p>
        <Box className={styles.inputContainer}>
          <Input
            value={form.email}
            onChange={(e) => {
              handleChange(e, "email");
            }}
            className={styles.input}
          />
          <div className={styles.inputHoverButton}>
            <Image
              width={24}
              height={24}
              alt="?"
              src={Send}
              // onClick={sendEmail}
            />
          </div>
        </Box>
      </Box>
    </Wrapper>
  );
};
