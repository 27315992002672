import { FC } from "react";
import styles from "./CalendarRange.module.scss";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Image from "lib/components/containers/Image/Image";
import Close from "assets/icons/close.svg"
interface CalendarRangeProps {
  calendar: any;
  setCalendar: React.Dispatch<any>;
}

export const CalendarRange: FC<CalendarRangeProps> = ({
  calendar,
  setCalendar,
}) => {
  return (
    <Wrapper className={styles.filterWrapper} alignment="center">
      {calendar[0].toLocaleString("en-us", {
        month: "short",
        year: "numeric",
        day: "numeric",
      })}
      {"-"}{" "}
      {calendar[1].toLocaleString("en-us", {
        month: "short",
        year: "numeric",
        day: "numeric",
      })}{" "}
      <Image
        width={16}
        height={16}
        alt="?"
        src={Close}
        className={styles.close}
        onClick={() => {
          setCalendar(new Date());
        }}
      />
    </Wrapper>
  );
};
