import styles from "./RelatedArticles.module.scss";
import "react-multi-carousel/lib/styles.css";

import { FC } from "react";

import Container from "lib/components/containers/Container/Container";
import Carousel from "react-multi-carousel";

import DetailsRelatedArticlesCard from "./Card";

interface RelatedArticlesProps {
  relatedArticles: any[];
}

export const RelatedArticles: FC<RelatedArticlesProps> = ({
  relatedArticles,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className={styles.relatedArticles}>
      <h3 className={styles.heading}>Other Articles You Will Like</h3>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        transitionDuration={500}
        className={styles.carousel}
      >
        {relatedArticles.map((article) => (
          <DetailsRelatedArticlesCard article={article} />
        ))}
      </Carousel>
    </div>
  );
};
