"use client";

import styles from "./Details.module.scss";

import { FC, Suspense, useEffect, useRef, useState } from "react";

import { Client } from "api/index";
import MetaTags from "components/layout/MetaTags";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import LoadingSpinner from "lib/components/other/LoadingSpinner/LoadingSpinner";
import { useApiKeyStore } from "store/apiKeyStore";
import { useArticleStore } from "store/article";
import { getServices } from "utils/fetch";

import DetailsContent from "./Content/Content";
import DetailsInfo from "./Info";
import DetailsRelatedArticles from "./RelatedArticles";
import Placeholder from "/assets/images/section-hero@2x.jpg";

interface DetailsProps {
  article: any;
  services: string[];

  relatedArticles: any;
}

const Details: FC<DetailsProps> = ({ article, services, relatedArticles }) => {
  if (!article) return;
  const { apiKey } = useApiKeyStore();
  const scrollRef = useRef<any>(null);
  // const [articles, setArticles] = useState([]);

  useEffect(() => {
    // const getData = async () => {
    //   const { articleDetails, relatedArticles } =
    //     await Client.StreamPluginClientAPI.articleContent(
    //       category as string,
    //       title as string,
    //       apiKey as string,
    //     );
    //   for (const article of relatedArticles) {
    //     article.publishedOn = new Date(article.publishedOn).toLocaleString(
    //       "en-US",
    //       {
    //         month: "short",
    //         day: "2-digit",
    //         year: "numeric",
    //       },
    //     );
    //   }
    //   const services = await getServices();
    //   articleDetails.type = "Article";
    // };
  }, []);
  // useEffect(() => {
  //   const getContent = async () => {
  //     const {
  //       articleDetails: { content },
  //       relatedArticles,
  //     } = await Client.StreamPluginClientAPI.articleContent(
  //       router.query.id as string,
  //       apiKey
  //     );
  //     for (const article of relatedArticles) {
  //       article.publishedOn = new Date(article.publishedOn).toLocaleString(
  //         "en-US",
  //         {
  //           month: "short",
  //           day: "2-digit",
  //           year: "numeric",
  //         }
  //       );
  //     }
  //     setArticle(content);
  //     setRelatedArticles(relatedArticles);
  //   };
  //   getContent();
  //   scrollRef.current.scrollIntoView({ behavior: "smooth" });
  // }, []);
  return (
    <>
      {" "}
      <MetaTags meta={article} />
      <Container className={styles.background} ref={scrollRef}>
        <Container className={styles.details} direction="column">
          <Box className={styles.content} direction="row">
            <Suspense fallback={<LoadingSpinner />}>
              <DetailsContent
                industryCategory={article?.industryCategory}
                topic={article?.service}
                content={article?.content}
                description={article.description}
                imageUrl={article.imageUrl}
                publishedOn={article.publishedOn}
                title={article.title}
              />
            </Suspense>
            <Suspense fallback={<LoadingSpinner />}>
              <DetailsInfo
                services={services}
                topic={article?.industryCategory}
                company={article?.companyName}
                address={article?.clientAddress}
                phone={article?.clientPhoneNumber}
              />
            </Suspense>
          </Box>
          {relatedArticles.length == 0 ? (
            <LoadingSpinner />
          ) : (
            <Suspense fallback={<LoadingSpinner />}>
              <DetailsRelatedArticles relatedArticles={relatedArticles} />
            </Suspense>
          )}
        </Container>
      </Container>
    </>
  );
};

export default Details;
