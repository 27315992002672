import { apiKey } from "credentials/api-key";
import { Article } from "pages/Home/types/article";
import { create } from "zustand";

interface State {
  apiKey: string;
  setApiKey: (apiKey: string) => void;
}

const useApiKeyStore = create<State>((set) => ({
  apiKey: apiKey,
  setApiKey: (apiKey) =>
    set({
      apiKey,
    }),
}));

export { useApiKeyStore };
