import styles from "./CheckedType.module.scss";

import { FC } from "react";

import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Image from "lib/components/containers/Image/Image";
import Close from "assets/icons/close.svg";

interface CheckedTypeProps {
  types: any;
  setTypes: React.Dispatch<any>;
  setCheckedTypes: React.Dispatch<any>;
}

export const CheckedType: FC<CheckedTypeProps> = ({
  types,
  setTypes,
  setCheckedTypes,
}) => {
  return types?.map((type: any, index: number) => (
    <Wrapper
      className={styles.filterWrapper}
      alignment="center"
      key={`${type} - ${index}`}
    >
      {type}{" "}
      <Image
        width={16}
        height={16}
        alt="?"
        src={ Close}
        className={styles.close}
        onClick={() => {
          setTypes((currentTypes: any) =>
            currentTypes.filter((currentType: any) => currentType !== type)
          );
          setCheckedTypes((currentCheckedValues: any) =>
            currentCheckedValues.filter(
              (checkedValue: any) => checkedValue !== type
            )
          );
        }}
      /><div ></div>
    </Wrapper>
  ));
};
