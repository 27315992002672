import { Article } from "pages/Home/types/article";
import { create } from "zustand";

export interface State {
  pathname: string;
  setPathname: (pathname: string) => void;
}

const usePathnameStore = create<State>((set) => ({
  pathname: window.location.pathname,
  setPathname: (pathname) =>
    set({
      pathname: `/${hasValidKeywordInPath()}${pathname}`,
    }),
}));

export { usePathnameStore };

const hasValidKeywordInPath = () => {
  let pathSegments = window.location.pathname.split("/").filter(Boolean);
  const index = pathSegments.findIndex((pathSegment) =>
    validKeywords.includes(pathSegment),
  );
  if (index == -1) {
    return pathSegments.join("/");
  } else return pathSegments.slice(0, index).join("/");
};

const validKeywords = [
  "article",
  "event",
  "specialty-guide",
  "webinar",
  "report",
];
