"use client";

import styles from "./Content.module.scss";

import { FC, useReducer } from "react";

import ArrowRight from "assets/icons/arrow_right_thin.svg";
import { baseUrl } from "credentials/url";
import parseStringToHTML from "html-react-parser";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Image from "lib/components/containers/Image/Image";
import LoadingSpinner from "lib/components/other/LoadingSpinner/LoadingSpinner";
import { marked } from "marked";
import { usePageStore } from "store/page";
import { usePathnameStore } from "store/pathname";
import { calculateReadingTime } from "utils/calculateReadingTime";
import { overrideLink } from "utils/overrideLink";

import { reverseFormatRoute } from "./../../../../utils/reverseFormatRoute";

export interface ContentProps {
  title: string;
  industryCategory: string;
  topic: string;
  publishedOn: string;
  imageUrl: string;
  content: string;
  description: string;
}

const Content: FC<ContentProps> = ({
  title,
  industryCategory,
  topic,
  publishedOn,
  imageUrl,
  content,
  description,
}) => {
  const { setPage } = usePageStore();
  let pathSegments = window.location.pathname.split("/").filter(Boolean);
  const validKeywords = [
    "article",
    "event",
    "specialty-guide",
    "webinar",
    "report",
  ];
  // Function to check if the path contains any of the valid keywords
  const hasValidKeywordInPath = () => {
    const index = pathSegments.findIndex((pathSegment) =>
      validKeywords.includes(pathSegment),
    );
    if (index == -1) pathSegments = [pathSegments[pathSegments.length - 1]];
    else pathSegments = pathSegments.slice(index - 1, pathSegments.length);
    return pathSegments[0];
  };
  hasValidKeywordInPath()
  const { setPathname } = usePathnameStore();

  const handleLinkClick = (e: any, link: string) => {
    overrideLink(e, link);

    setPathname(link);
  };
  return (
    <Container className={styles.content} direction="column">
      <Container className={styles.articleContainer} direction="column">
        <Box className={styles.navigation} alignment="left">
          <a
            href="/"
            className={styles.link}
            onClick={(e) => {
              handleLinkClick(e, "/");
            }}
            onAuxClick={(e) => {
              handleLinkClick(e, "/");
            }}
            onContextMenu={(e) => {
              handleLinkClick(e, "/");
            }}
          >
            Home{" "}
          </a>
          <Image
            width={10}
            height={10}
            alt="?"
            src={ArrowRight}
            className={styles.arrow}
          />{" "}
          <a
            href={`/article/${pathSegments[2]}`}
            className={styles.link}
            onClick={(e) => {
              handleLinkClick(e, `/article/${pathSegments[2]}`);
            }}
            onAuxClick={(e) => {
              handleLinkClick(e, `/article/${pathSegments[2]}`);
            }}
            onContextMenu={(e) => {
              handleLinkClick(e, `/article/${pathSegments[2]}`);
            }}
          >
            {industryCategory}
          </a>
          <Image
            width={10}
            height={10}
            alt="?"
            src={ArrowRight}
            className={styles.arrow}
          />{" "}
          <a
            href={`/article/${pathSegments[2]}/${pathSegments[3]}`}
            className={styles.link}
            onClick={(e) => {
              handleLinkClick(e, `/article/${pathSegments[2]}/${pathSegments[3]}`);
            }}
            onAuxClick={(e) => {
              handleLinkClick(e, `/article/${pathSegments[2]}/${pathSegments[3]}`);
            }}
            onContextMenu={(e) => {
              handleLinkClick(e, `/article/${pathSegments[2]}/${pathSegments[3]}`);
            }}
          >
            {topic}
          </a>
          <Image
            width={10}
            height={10}
            alt="?"
            src={ArrowRight}
            className={styles.arrow}
          />{" "}
          <a>{title}</a>
        </Box>

        <div className={styles.article}>
          <p className={styles.topic}>{industryCategory}</p>
          <h1 className={styles.heading}>{title}</h1>
          <h2 className={styles.description}>{description}</h2>
          <p className={styles.createdBy}>
            {/* By John Doe, Jane Doe, Chris Jenkin, Mark Lang, and Dmitry Kapur */}
          </p>
          <Box alignment="left" className={styles.dateContainer}>
            <p className={styles.date}>
              {new Date(publishedOn).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </p>
            <div className={styles.circle}></div>
            <p className={styles.date}>
              {calculateReadingTime(content)} min read
            </p>
          </Box>
          <hr className={styles.line} />
          {imageUrl ? (
            <Image
              height={450}
              alt="?"
              src={imageUrl}
              className={styles.image}
            />
          ) : (
            <Image
              height={450}
              alt="?"
              src={`${baseUrl}/assets/images/image@2x.png`}
              className={styles.image}
            />
          )}

          <div className={styles.text}>
            {!content ? (
              <LoadingSpinner />
            ) : (
              <div>
                {content && parseStringToHTML(marked.parse(content) as string)}
              </div>
            )}
          </div>
        </div>
      </Container>
      <hr className={styles.line} />
    </Container>
  );
};

export default Content;
