import { create } from "zustand";

interface State {
  search: string;
  setSearch: (search: string) => void;
}

const useSearchStore = create<State>((set) => ({
  search: "",
  setSearch: (search) =>
    set({
      search,
    }),
}));

export { useSearchStore };
