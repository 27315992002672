import Axios from "axios";
import { apiKey } from "credentials/api-key";

import {
  AdminSettingsClient as AdminSettingsClientAPI,
  ArticlesClient as ArticlesClientAPI,
  AuthenticationClient as AuthenticationClientAPI,
  FiltersClient as FiltersClientAPI,
  OnboardingFormClient as OnboardingFormClientAPI,
  StreamPluginClient as StreamPluginClientAPI,
} from "./api";

export const baseURL =
  import.meta.env.VITE_SERVER_URL ||
  //'https://fafa-2a06-63c1-9003-0-30db-5396-a072-1335.ngrok-free.app'
  "https://gstream-back.gwebchatdemo.gotchahosting.com";

// Utility function to convert snake_case to camelCase
const snakeToCamel = (str: string): string =>
  str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

// Recursive function to convert keys to camelCase
const convertKeysToCamel = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamel);
  } else if (obj && typeof obj === "object" && obj.constructor === Object) {
    return Object.entries(obj).reduce(
      (acc, [key, value]) => {
        acc[snakeToCamel(key)] = convertKeysToCamel(value);
        return acc;
      },
      {} as Record<string, any>,
    );
  }
  return obj;
};

export const axiosUpgrade = (signal?: AbortSignal) => {
  // Create an Axios instance
  const axios = Axios.create({ baseURL, signal: signal });
  axios.defaults.withCredentials = true;
  // Add a request interceptor to handle errors
  axios.interceptors.response.use(
    (response) => {
      if (response.data) {
        response.data = convertKeysToCamel(response.data);
      }
      return Promise.resolve(response);
    },
    async ({ response }) => {
      const originalRequest = response.config;
      if (response?.status === 401 && !originalRequest._retry) {
        try {
          // // Refresh token
          // // const {
          // //   data: { access_token, status_code, detail },
          // // } = await Axios.post(
          // //   `${baseURL} + "/api/v1/stream_plugin/refresh_token`,
          // //   null,
          // //   { withCredentials: true },
          // // );
          // if (status_code === 401) {
          //   throw new Error(detail);
          // }
          // // Store new token and set authorization header
          // localStorage.setItem("token", access_token);
          // axios.defaults.headers.common["Authorization"] =
          //   `Bearer ${access_token}`;
          // originalRequest._retry = true;
          // // Retry original request with new token
          // return axios(originalRequest);
        } catch (e: any) {
          // Handle failed refresh token
          localStorage.removeItem("token");
          alert("Unauthorized access");
          // alert(e.message || "Session expired, please sign in again");
          // Redirect user to login page or show login modal
        }
      }

      return Promise.reject(response);
    },
  );
  axios.interceptors.request.use(
    async (config) => {
      if (typeof window !== "undefined") {
        try {
          const token = localStorage.getItem("token");
          if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
          }

          return config;
        } catch (e) {
          console.log(e);
          return config;
        }
      } else {
        return config;
      }
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  return axios;
};

export abstract class Client {
  public static AdminSettingsClientAPI = new AdminSettingsClientAPI(
    baseURL,
    axiosUpgrade(),
  );
  public static ArticlesClientAPI = new ArticlesClientAPI(
    baseURL,
    axiosUpgrade(),
  );
  public static OnboardingFormClientAPI = new OnboardingFormClientAPI(
    baseURL,
    axiosUpgrade(),
  );
  public static FiltersClientAPI = new FiltersClientAPI(
    baseURL,
    axiosUpgrade(),
  );
  public static AuthenticationClientAPI = new AuthenticationClientAPI(
    baseURL,
    axiosUpgrade(),
  );
  public static StreamPluginClientAPI = new StreamPluginClientAPI(
    baseURL,
    axiosUpgrade(),
  );
}

export default axiosUpgrade;
