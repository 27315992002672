import { CSSProperties, FC, ReactNode } from "react";
// import { fontFamilyMap } from "../maps";
import { FamilyType } from "../types";
import styles from "./Text.module.scss";

interface TextProps {
  color?: "none" | string;
  fontSize?: "none" | string;
  fontStyle?: "none" | string;
  fontWeight?: "none" | string;
  lineHeight?: "none" | string;
  children?: ReactNode;
  className?: string;
  sx?: CSSProperties;
}

const colorMap = {
  white: styles.white,
  "primary-100": styles.primary100,
  "neutral-100": styles.neutral100,
  "neutral-200": styles.neutral200,
  "neutral-300": styles.neutral300,
  "neutral-500": styles.neutral500,
};
const Text: FC<TextProps> = ({
  fontStyle = "none",
  fontSize = "none",
  fontWeight = "none",
  lineHeight = "none",
  color = "none",
  children,
  className,
  sx,
}) => {
  let classRecord = "";
  if (className) classRecord += `${className} `;

  return (
    <p
      className={classRecord !== "" ? classRecord : undefined}
      style={{
        fontStyle: fontStyle !== "none" ? fontStyle : undefined,
        fontSize: fontSize !== "none" ? fontSize : undefined,
        fontWeight: fontWeight !== "none" ? fontWeight : undefined,
        color: color !== "none" ? color : undefined,
        lineHeight: lineHeight !== "none" ? lineHeight : undefined,
        ...sx,
      }}
    >
      {children}
    </p>
  );
};

export default Text;
