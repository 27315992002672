import { useEffect } from "react";

import { useArticleStore } from "store/article";
import { usePageStore } from "store/page";
// import { saveArticle, savePage } from "utils/localStorage";

export const useSaveState = () => {
  const { page } = usePageStore();
  const { article } = useArticleStore();

  // Save store states to localStorage on change
  useEffect(() => {
    // saveArticle(article);
  }, [article]);

  useEffect(() => {
    // savePage(page);
  }, [page]);
};
