import { FC, forwardRef } from "react";

import { alignmentMap } from "../maps";
import { ContainersProps } from "../types";

const Container: FC<ContainersProps> = forwardRef(
  (
    {
      className = "none",
      id = "none",
      display = "flex",
      padding = "0",
      paddingTop = "0",
      paddingRight = "0",
      paddingBottom = "0",
      paddingLeft = "0",

      margin = "0",
      marginTop = "0",
      marginRight = "0",
      marginBottom = "0",
      marginLeft = "0",
      borderRadius = "0",
      children,
      sx,
      gap = 0,
      justifyContent = "none",
      alignItems = "none",
      position = "none",
      top = "none",
      bottom = "none",
      right = "none",
      left = "none",
      textAlign = "none",
      direction = "row",
      alignment = "none",
      flex = "none",
      flexWrap = "none",
      flexBasis = "none",
      overflowY = "none",

      cursor = "default",
      width = "none",
      height = "none",
      minWidth = "none",
      minHeight = "none",
      maxWidth = "none",
      maxHeight = "none",
      zIndex = "none",
      onClick,
      onMouseEnter,
      onMouseLeave,
      onKeyDown,
    },
    ref,
  ) => {
    const classRecord = className !== "none" ? className : undefined;
    let alignmentRecord = { ...alignmentMap[alignment] };
    alignmentRecord.justifyContent =
      justifyContent !== "none"
        ? justifyContent
        : alignmentRecord.justifyContent;
    alignmentRecord.alignItems =
      alignItems !== "none" ? alignItems : alignmentRecord.alignItems;

    if (direction === "column")
      alignmentRecord = {
        justifyContent: alignmentRecord.alignItems,
        alignItems: alignmentRecord.justifyContent,
      };

    return (
      <div
        className={classRecord || ""}
        id={(id !== "none" ? id : undefined) || ""}
        style={{
          display: display,
          padding:
            padding !== "0"
              ? padding
              : paddingTop !== "0" ||
                  paddingRight !== "0" ||
                  paddingBottom !== "0" ||
                  paddingLeft !== "0"
                ? `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`
                : undefined,

          margin:
            margin !== "0"
              ? margin
              : marginTop !== "0" ||
                  marginRight !== "0" ||
                  marginBottom !== "0" ||
                  marginLeft !== "0"
                ? `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`
                : undefined,

          borderRadius: borderRadius !== "0" ? borderRadius : undefined,
          gap: gap !== 0 ? gap + "px" : undefined,
          flexDirection: direction !== "row" ? direction : undefined,
          justifyContent: alignmentRecord.justifyContent,
          alignItems: alignmentRecord.alignItems,
          flex: flex !== "none" ? flex : undefined,
          flexWrap: flexWrap !== "none" ? flexWrap : undefined,

          overflowY: overflowY !== "none" ? overflowY : undefined,
          cursor: cursor !== "default" ? cursor : undefined,

          width: width !== "none" ? width : undefined,
          height: height !== "none" ? height : undefined,
          minWidth: minWidth !== "none" ? minWidth : undefined,
          minHeight: minHeight !== "none" ? minHeight : undefined,
          maxWidth: maxWidth !== "none" ? maxWidth : undefined,
          maxHeight: maxHeight !== "none" ? maxHeight : undefined,
          position: position !== "none" ? position : undefined,
          top: top !== "none" ? top : undefined,
          bottom: bottom !== "none" ? bottom : undefined,
          right: right !== "none" ? right : undefined,
          left: left !== "none" ? left : undefined,
          textAlign: textAlign !== "none" ? textAlign : undefined,
          zIndex: zIndex !== "none" ? zIndex : undefined,
          ...sx,
        }}
        ref={ref}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onKeyDown={onKeyDown}
      >
        {children}
      </div>
    );
  },
);

export default Container;
