import "./App.css";

import { useEffect, useState } from "react";

import EventsPage from "components/pages/Events/Events.lazy";
import HomePage from "components/pages/Home/Home";
import NotFound from "components/pages/NotFound/NotFound.lazy";
import ReportPage from "components/pages/Report/Report.lazy";
import SpecialtyGuide from "components/pages/SpecialtyGuide/SpecialtyGuide";
import WebinarPage from "components/pages/Webinar/Webinar.lazy";
import { useSaveState } from "hooks/useSaveState";
import Button from "lib/components/clickables/Button/Button";
import Container from "lib/components/containers/Container/Container";
import LoadingSpinner from "lib/components/other/LoadingSpinner/LoadingSpinner";
import ModalShare from "modals/Share/Share.lazy";
import DetailsPage from "pages/Details/Details";
import { Route, Router, Routes, useNavigate } from "react-router-dom";
import { useApiKeyStore } from "store/apiKeyStore";
import { useArticleStore } from "store/article";
import { useAuthStore } from "store/auth";
import { useModalStore } from "store/modal";
import { usePageStore } from "store/page";
import { usePathnameStore } from "store/pathname";
import {
  getArticleContent,
  getArticles,
  getEventPreview,
  getIndustry,
  getReportPreview,
  getServices,
  getSpecialtyGuidePreview,
  getTypes,
  getWebinarPreview,
} from "utils/fetch";

import { Client } from "./api";
import { AuthClientRequest } from "./api/api";
import reactLogo from "./assets/react.svg";
import { reverseFormatRoute } from "./utils/reverseFormatRoute";
import viteLogo from "/vite.svg";

function App() {
  const scriptTag = document.getElementById("gstream-plugin-script");

  // Extract the data-client-id attribute
  const clientId = scriptTag?.getAttribute("data-client-id") ||
  "landscape-matiral-&-firewood-sales-inc-123";
  const { page } = usePageStore();
  const { apiKey } = useApiKeyStore();
  const { setAuthorized } = useAuthStore();
  const { setPage } = usePageStore();
  const { article } = useArticleStore();
  const { modal } = useModalStore();
  const [articleList, setArticleList] = useState([]);
  const [articleContent, setArticleContent] = useState<any>({});
  const [sessionAuthorized, setSessionAuthorized] = useState(false);

  const [services, setServices] = useState([]);
  const [industry, setIndustry] = useState("");
  const [typeList, setTypeList] = useState("");
  const [articleOnly, setArticleOnly] = useState(false);
  const [topic, setTopic] = useState<string | undefined>("");
  const [event, setEvent] = useState<any>();
  const [report, setReport] = useState<any>();
  const [webinar, setWebinar] = useState<any>();
  const [bio, setBio] = useState("");
  const [heroSettings, setHeroSettings] = useState({
    h1: "",
    heroImageUrl: "",
    introText: "",
    welcomeText: "",
  });

  const [specialtyGuide, setSpecialtyGuide] = useState<any>();

  const [loading, setLoading] = useState(true);

  const { pathname, setPathname } = usePathnameStore();
  let pathSegments = window.location.pathname.split("/").filter(Boolean);
  const validKeywords = [
    "article",
    "event",
    "specialty-guide",
    "webinar",
    "report",
  ];
  // Function to check if the path contains any of the valid keywords
  const hasValidKeywordInPath = () => {
    const index = pathSegments.findIndex((pathSegment) =>
      validKeywords.includes(pathSegment),
    );
    if (index == -1) pathSegments = [pathSegments[pathSegments.length - 1]];
    else pathSegments = pathSegments.slice(index - 1, pathSegments.length);
    return pathSegments[0];
  };
  hasValidKeywordInPath();

  const getArticleList = async (articlesOnly?: boolean, topic?: string) => {
    setLoading(true);
    try {
      const [articlesData, servicesData, typeListData, industryData] =
        await Promise.all([
          getArticles(articlesOnly, topic),
          getServices(),
          getTypes(),
          getIndustry(),
        ]);
      if (
        (pathSegments.length === 2 || pathSegments.length === 4) &&
        articlesData.articles.length == 0
      ) {
        throw new Error("Not Found");
      }
      if (pathSegments.length === 3) {
        const industry = reverseFormatRoute(pathSegments[2]);
        if (industry.toLocaleLowerCase() !== industryData.toLocaleLowerCase())
          throw new Error("Not Found");
      }

      setArticleList(articlesData.articles);
      setHeroSettings(articlesData.heroSettings);
      setBio(articlesData.companyBio);
      setServices(servicesData);
      setIndustry(industryData);
      setTypeList(typeListData);
      setArticleOnly(articlesOnly || false);
      setTopic(topic);
    } catch (e) {
      setPage("not-found");
    } finally {
      setLoading(false);
    }
  };

  const getArticle = async () => {
    setLoading(true);
    const [content, servicesData] = await Promise.all([
      getArticleContent(
        reverseFormatRoute(pathSegments[2]),
        reverseFormatRoute(pathSegments[4]),
      ),
      getServices(),
    ]);

    setArticleContent(content);
    setServices(servicesData);
    setLoading(false);
  };

  const getEvent = async () => {
    setLoading(true);
    try {
      const [eventData, servicesData, industryData] = await Promise.all([
        getEventPreview(article?.title || reverseFormatRoute(pathSegments[2])),
        getServices(),
        getIndustry(),
      ]);

      setEvent(eventData);
      setServices(servicesData);
      setIndustry(industryData);
    } catch (e) {
      setPage("not-found");
    } finally {
      setLoading(false);
    }
  };

  const getWebinar = async () => {
    setLoading(true);
    try {
      const [webinarData, servicesData, industryData] = await Promise.all([
        getWebinarPreview(
          article?.title || reverseFormatRoute(pathSegments[2]),
        ),
        getServices(),
        getIndustry(),
      ]);

      setWebinar(webinarData);
      setServices(servicesData);
      setIndustry(industryData);
    } catch (e) {
      setPage("not-found");
    } finally {
      setLoading(false);
    }
  };

  const getReport = async () => {
    setLoading(true);
    try {
      const [reportData, servicesData, industryData] = await Promise.all([
        getReportPreview(article?.title || reverseFormatRoute(pathSegments[2])),
        getServices(),
        getIndustry(),
      ]);

      setReport(reportData);
      setServices(servicesData);
      setIndustry(industryData);
    } catch (e) {
      setPage("not-found");
    } finally {
      setLoading(false);
    }
  };

  const getSpecialtyGuide = async () => {
    setLoading(true);
    try {
      const [specialtyGuideData, industryData] = await Promise.all([
        getSpecialtyGuidePreview(
          article?.title || reverseFormatRoute(pathSegments[2]),
        ),
        getIndustry(),
      ]);

      setSpecialtyGuide(specialtyGuideData);
      setIndustry(industryData);
    } catch (e) {
      setPage("not-found");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   setPathname(window.location.pathname);
  // }, [window.location.pathname]);

  useEffect(() => {
    const authorize = async () => {
      const res = await Client.AuthenticationClientAPI.authenticateClient(
        new AuthClientRequest({ client_id: clientId }),
      );
      setSessionAuthorized(true);
      console.log(res);
    };
    authorize();
  }, []);

  useEffect(() => {
    if (sessionAuthorized) {
      if (pathSegments.length === 0) {
        getArticleList(false, "");
        setPage("home");
      } else if (pathSegments.length === 1) {
        getArticleList(false, "");
        setPage("home");
      } else if (pathSegments.length === 2) {
        if (
          pathSegments[1] === "article" ||
          pathSegments[1] === "event" ||
          pathSegments[1] === "specialty-guide" ||
          pathSegments[1] === "webinar" ||
          pathSegments[1] === "report"
        ) {
          getArticleList(true, "");
          setPage("home");
        } else setPage("not-found");
      } else if (pathSegments.length === 3) {
        
        if (pathSegments.includes("event")) {
          getEvent();
          setPage("events");
        }
        if (pathSegments.includes("webinar")) {
          getWebinar();
          setPage("webinars");
        }
        if (pathSegments.includes("report")) {
          getReport();
          setPage("reports");
        }
        if (pathSegments.includes("specialty-guide")) {
          getSpecialtyGuide();
          setPage("specialty-guide");
        }
        if (pathSegments.includes("article")) {
          getArticleList(true, "");
          setPage("home");
        }
      } else if (pathSegments.length === 4 && pathname.includes("article")) {
        getArticleList(true, reverseFormatRoute(pathSegments[3]));
        setPage("home");
      } else if (pathSegments.length == 5) {
        getArticle();
        setPage("details");
      }
    }
  }, [pathname, sessionAuthorized]);

  // useEffect(() => {
  //   // fetch call
  //   const checkAuth = async () => {
  //     const accessToken = localStorage.getItem("token");

  //     if (accessToken) {
  //       try {
  //         const { accessToken, role } =
  //           await Client.StreamPluginClientAPI.refresh_token(apiKey);
  //         localStorage.setItem("token", accessToken);
  //         setAuthorized(true);
  //       } catch (e) {
  //         console.log(e);
  //         localStorage.removeItem("token");
  //         setAuthorized(false);
  //       }
  //     } else {
  //       setAuthorized(false);
  //     }
  //   };
  //   checkAuth();
  // }, []); // Empty dependency array ensures useEffect runs once

  useEffect(() => {
    const popstate = () => {
      setPathname(window.location.pathname);
    };

    window.addEventListener("popstate", popstate);

    return () => {
      window.removeEventListener("popstate", popstate);
    };
  }, []);

  useEffect(() => {
    const links = [
      { rel: "preconnect", href: "https://fonts.googleapis.com" },
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com",
        crossOrigin: "true",
      },
      {
        rel: "stylesheet",
        href: "https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap",
      },
    ];

    links.forEach(({ rel, href, crossOrigin }) => {
      if (!document.querySelector(`link[href="${href}"]`)) {
        const link = document.createElement("link");
        link.rel = rel;
        link.href = href;
        if (crossOrigin) link.crossOrigin = crossOrigin;
        document.head.appendChild(link);
      }
    });

    return () => {
      links.forEach(({ href }) => {
        const existingLink = document.querySelector(`link[href="${href}"]`);
        if (existingLink) {
          existingLink.remove();
        }
      });
    };
  }, []);

  useSaveState();
  return (
    <>
      {/* // <Container direction="column"> */}
      {loading ? (
        <LoadingSpinner />
      ) : !sessionAuthorized ? (
        "Unauthorized access"
      ) : (
        (page == "home" && (
          <HomePage
            articles={articleList}
            heroSettings={heroSettings}
            services={services}
            industry={industry}
            typeList={typeList}
            topic={topic || undefined}
            type={articleOnly ? "Articles" : undefined}
          />
        )) ||
        (page == "details" && (
          <DetailsPage
            article={articleContent?.articleDetails}
            relatedArticles={articleContent?.relatedArticles}
            services={services}
          />
        )) ||
        (page == "events" && (
          <EventsPage event={event} industry={industry} services={services} />
        )) ||
        (page == "webinars" && (
          <WebinarPage
            webinar={webinar}
            industry={industry}
            services={services}
          />
        )) ||
        (page == "reports" && (
          <ReportPage report={report} industry={industry} services={services} />
        )) ||
        (page == "specialty-guide" && (
          <SpecialtyGuide guideProp={specialtyGuide} />
        )) ||
        (page == "not-found" && <NotFound />)
      )}

      {/* {page == "specialty-guide" && (
        <SpecialtyGuidePage
          title={""}
          industryCategory={""}
          publishedOn={""}
          imageUrl={""}
          content={""}
        />
      )}
      {modal == "share" && <ModalShare />} */}
      {/* </Container> */}
    </>
  );
}

export default App;
