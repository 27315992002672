import React, { Suspense, lazy } from "react";

import { ReportProps } from "./Report";

const LazyReport = lazy(() => import("./Report"));

const ReportPage = (
  props: JSX.IntrinsicAttributes & ReportProps & { children?: React.ReactNode }
) => (
  <Suspense fallback={null}>
    <LazyReport {...props} />
  </Suspense>
);

export default ReportPage;
