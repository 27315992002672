import { AlignmentType } from "./types";

export const alignmentMap: Record<
  AlignmentType,
  { justifyContent: string | undefined; alignItems: string | undefined }
> = {
  "top-left": { justifyContent: "flex-start", alignItems: "flex-start" },
  top: { justifyContent: "center", alignItems: "flex-start" },
  "top-right": { justifyContent: "flex-end", alignItems: "flex-start" },
  left: { justifyContent: "flex-start", alignItems: "center" },
  center: { justifyContent: "center", alignItems: "center" },
  right: { justifyContent: "flex-end", alignItems: "center" },
  "bottom-left": { justifyContent: "flex-start", alignItems: "flex-end" },
  bottom: { justifyContent: "center", alignItems: "flex-end" },
  "bottom-right": { justifyContent: "flex-end", alignItems: "flex-end" },
  "top-normal": { justifyContent: undefined, alignItems: "flex-start" },
  "center-normal": { justifyContent: undefined, alignItems: "center" },
  "bottom-normal": { justifyContent: undefined, alignItems: "flex-bottom" },
  none: { justifyContent: undefined, alignItems: undefined },
};
