import styles from "./Select.module.scss";

import { FC } from "react";

import Image from "lib/components/containers/Image/Image";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";

import DropdownArrow from "assets/icons/arrow_drop_down.svg";

interface SelectProps {
  name: string;
  handleClick?: () => void;
}

export const Select: FC<SelectProps> = ({ name, handleClick }) => {
  return (
    <Wrapper className={styles.select} onClick={handleClick} alignment="center">
      {name}
      <Image width={20} height={20} alt="?" src={DropdownArrow} />
    </Wrapper>
  );
};
