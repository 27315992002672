import styles from "./CheckedTopic.module.scss";

import { FC } from "react";

import Close from "assets/icons/close.svg";
import Image from "lib/components/containers/Image/Image";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";

interface CheckedTopicProps {
  topics: any;
  setTopics: React.Dispatch<any>;
  setCheckedTopics: React.Dispatch<any>;
}

export const CheckedTopic: FC<CheckedTopicProps> = ({
  topics,
  setTopics,
  setCheckedTopics,
}) => {
  return topics.map((topic: any, index: number) => (
    <Wrapper
      className={styles.filterWrapper}
      alignment="center"
      key={`${topic} - ${index}`}
    >
      {topic}{" "}
      <Image
        width={16}
        height={16}
        alt="?"
        src={Close}
        className={styles.close}
        onClick={() => {
          setTopics((currentTopics: any) =>
            currentTopics.filter((currentTopic: any) => currentTopic !== topic)
          );
          setCheckedTopics((currentCheckedValues: any) =>
            currentCheckedValues.filter(
              (checkedValue: any) => checkedValue !== topic
            )
          );
        }}
      />
    </Wrapper>
  ));
};
