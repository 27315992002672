import styles from "./Input.module.scss";

import React, { ChangeEvent, FC, forwardRef } from "react";

import { ContainersProps } from "lib/components/containers/types";

interface InputProps {
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  width?: string | number;
  padding?: string | "0";
  props?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  design?: "normal" | "search" | "schedule-call" | "congen";
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const designMap = {
  normal: styles.normal,
  search: styles.search,
  congen: styles.congen,
  "schedule-call": styles.scheduleCall,
};

const Input: FC<InputProps & ContainersProps> = forwardRef(
  (
    {
      placeholder,
      type,
      padding = "0",
      width = "none",
      props,
      design = "normal",
      value = "",
      className = "",
      onChange = () => {},
    },
    ref,
  ) => {
    const classRecord = `${designMap[design]} ${styles.Input} ${className}`;
    return (
      <input
        className={classRecord}
        placeholder={placeholder}
        type={type}
        style={{
          width: width !== "none" ? width : undefined,
          padding: padding !== "0" ? padding : undefined,
        }}
        value={value}
        onChange={onChange}
        ref={ref}
        {...props}
      />
    );
  },
);

export default Input;
