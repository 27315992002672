import { create } from "zustand";

export type Page =
  | "home"
  | "details"
  | "specialty-guide"
  | "events"
  | "webinars"

  | "reports"
  | "not-found";
interface State {
  page: Page;
  setPage: (page: Page) => void;
}

const usePageStore = create<State>((set) => ({
  page: "home",
  setPage: (page) =>
    set({
      page,
    }),
}));

export { usePageStore };
