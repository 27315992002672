import styles from "./Share.module.scss";

import { FC } from "react";

import Button from "lib/components/clickables/Button/Button";
import { useModalStore } from "store/modal";
import Image from "lib/components/containers/Image/Image";
import ShareIcon from "assets/icons/share.svg";

interface ShareProps {}

export const Share: FC<ShareProps> = ({}) => {
  const { setModal, setModalData } = useModalStore();
  return (
    <Button
      className={styles.share}
      type="sign-up"
      onClick={() => {
        setModal("share");
      }}
    >
      <Image
        
        width={24}
        height={24}
        alt="?" src={ShareIcon} />
      Share{" "}
    </Button>
  );
};
