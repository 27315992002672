import styles from "./CreatePassword.module.scss";

import { FC, useState } from "react";

import { RegisterUserRequest } from "api/api";
import { Client } from "api/index";
import Button from "lib/components/clickables/Button/Button";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Input from "lib/components/forms/Input/Input";
import { useForm } from "lib/hooks/useForm";
import { toast } from "react-toastify";
import { useApiKeyStore } from "store/apiKeyStore";

export interface CreatePasswordProps {
  email: string;
  handleEdit: () => void;
}

const CreatePassword: FC<CreatePasswordProps> = ({ email, handleEdit }) => {
  const { form, handleChange } = useForm({
    password: "",
  });
  const { apiKey } = useApiKeyStore();

  const [showPassword, setShowPassword] = useState(false);
  const [registering, setRegistering] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container
      className={styles.createPassword}
      direction="column"
      alignment="center"
    >
      <p className={styles.heading}>Create a password</p>
      <Box className={styles.form} direction="column">
        <Wrapper className={styles.inputWrapper}>
          <label className={styles.label}>Email Address</label>
          <Box className={styles.inputContainer}>
            <Input
              design="normal"
              type="email"
              value={email}
              props={{ disabled: true }}
              className={`${styles.input} ${styles.disabledInput}`}
            />
            <div className={styles.inputHoverButton} onClick={handleEdit}>
              {"Edit"}
            </div>
          </Box>
        </Wrapper>
        <Wrapper className={styles.inputWrapper}>
          <label className={styles.label}>Password</label>
          <Box className={styles.inputContainer}>
            <Input
              design="normal"
              type={showPassword ? "text" : "password"}
              value={form.password}
              onChange={(e) => {
                handleChange(e, "password");
              }}
              className={styles.input}
            />
            <div
              onClick={togglePasswordVisibility}
              className={styles.inputHoverButton}
            >
              {showPassword ? "Hide" : "Show"}
            </div>
          </Box>
        </Wrapper>
        <div className={styles.terms}>
          By continuing, you agree to the{" "}
          <span className={styles.underline}>Terms of Sale</span>,{" "}
          <span className={styles.underline}>Terms of Service</span>, and{" "}
          <span className={styles.underline}>Privacy Policy</span>.
        </div>

        <Button
          type="primary"
          disabled={registering}
          onClick={async () => {
            try {
              setRegistering(true);
              await Client.StreamPluginClientAPI.register(
                new RegisterUserRequest({
                  email: email,
                  password: form.password,
                }),
              );
              setRegistering(false);

              toast.success("Account created successfully!");
            } catch (e) {
              setRegistering(false);
              toast.error(
                "An error occurred while creating your account, please try again.",
              );
            }
          }}
        >
          Create Account
        </Button>
      </Box>
    </Container>
  );
};

export default CreatePassword;
