import React, { Suspense, lazy } from "react";

import { NotFoundProps } from "./NotFound";


const LazyNotFound = lazy(() => import("./NotFound"));

const NotFound = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode },
) => (
  <Suspense fallback={null}>
    <LazyNotFound {...props} />
  </Suspense>
);

export default NotFound;
