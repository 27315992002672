import styles from "./Page.module.scss";

import { FC } from "react";

import PageIcon from "assets/icons/page.svg";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";

import SpecialtyGuideNavigationSubPage from "../SubPage";
import Image from "lib/components/containers/Image/Image";

interface PageProps {
  page: any;
  setPage: React.Dispatch<any>;
}

export const Page: FC<PageProps> = ({ page, setPage }) => {
  return (
    <Wrapper
      className={styles.page}
      direction="column"
      onClick={() => {
        setPage(page);
      }}
    >
      <a href={`#${page.title}`}>
        <Wrapper className={styles.name}>
          <Image width={24} height={24} alt="?" src={PageIcon} />
          {page.title}
        </Wrapper>
      </a>
      {page.subPages.map((subPage: any) => (
        <SpecialtyGuideNavigationSubPage
          subPage={subPage.title}
          page={page.title}
        />
      ))}
    </Wrapper>
  );
};
