import styles from "./Services.module.scss";

import { FC } from "react";

import ArrowRight from "assets/icons/arrow-circle-right@2x.png";
import Button from "lib/components/clickables/Button/Button";
import Box from "lib/components/containers/Box/Box";
import Image from "lib/components/containers/Image/Image";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import { usePageStore } from "store/page";
import { usePathnameStore } from "store/pathname";
import { formatRoute } from "utils/formatRoute";
import { overrideLink } from "utils/overrideLink";

interface ServicesProps {
  services: string[];
  industry: string;
}

export const Services: FC<ServicesProps> = ({ services, industry }) => {
  const { setPage } = usePageStore();
  const { setPathname } = usePathnameStore();
  const handleLinkClick = (e: any, service: string) => {
    overrideLink(
      e,
      `/article/${formatRoute(industry)}/${formatRoute(service)}`,
    );
    setPathname(`/article/${formatRoute(industry)}/${formatRoute(service)}`);
  };
  return (
    <Box
      direction="column"
      className={styles.services}
      alignItems="space-between"
    >
      <div className={styles.content}>
        <h4 className={styles.title}>Need Help?</h4>
        <p className={styles.description}>
          Check out these related products that can help:
        </p>
        <Box
          alignment="top-left"
          direction="column"
          gap={10}
          className={styles.links}
        >
          {services?.map((service) => (
            <Wrapper className={styles.item} key={service}>
              {" "}
              <Image
                width={24}
                height={24}
                alt="?"
                src={ArrowRight}
                className={styles.icon}
              />
              <a
                className={styles.link}
                href={`/article/${formatRoute(industry)}/${formatRoute(service)}`}
                onClick={(e) => {
                  handleLinkClick(e, service);
                }}
                onAuxClick={(e) => {
                  handleLinkClick(e, service);
                }}
                onContextMenu={(e) => {
                  handleLinkClick(e, service);
                }}
              >
                {service}
              </a>
            </Wrapper>
          ))}
          {/* {services?.map((service) => (
            <Wrapper className={styles.item}>
              {" "}
              <Image
                width={24}
                height={24}
                alt="?"
                src={baseUrl + "/assets/icons/arrow-circle-right@2x.png"}
                className={styles.icon}
              />
              <a className={styles.link} href={formatRoute(service)}>
                {service}
              </a>
            </Wrapper>
          ))}
          {services.map((service) => (
            <Wrapper className={styles.item}>
              {" "}
              <Image
                width={24}
                height={24}
                alt="?"
                src={baseUrl + "/assets/icons/arrow-circle-right@2x.png"}
                className={styles.icon}
              />
              <a className={styles.link} href={formatRoute(service)}>
                {service}
              </a>
            </Wrapper>
          ))} */}
          {/* <Wrapper className={styles.item}>
            <a className={styles.link}>
              <Image
                width={24}
                height={24}
                alt="?"
                src={baseUrl + "/assets/icons/arrow-circle-right@2x.png"}
                className={styles.icon}
              />
              Product Service Name 02
            </a>
          </Wrapper>
          <Wrapper className={styles.item}>
            <a className={styles.link}>
              <Image
                width={24}
                height={24}
                alt="?"
                src={baseUrl + "/assets/icons/arrow-circle-right@2x.png"}
                className={styles.icon}
              />
              Product Service Name is Long And Ends In Second Line
            </a>
          </Wrapper> */}
        </Box>
      </div>

      <Box className={styles.buttonContainer}>
        <Button type="primary" sx={{ width: "100%" }}>
          View All Services
        </Button>
      </Box>
    </Box>
  );
};
