import { useRef, useEffect } from "react";

export const useSkipFirstRender = (
  callback: () => void,
  dependencies: any[]
) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // Skip the first render
      return;
    }
    callback(); // Trigger the callback on subsequent renders
  }, dependencies);
};
