import styles from "./Filter.module.scss";
import "react-calendar/dist/Calendar.css";

import { Dispatch, FC, SetStateAction, useRef, useState } from "react";

import Magnifying from "assets/icons/magnifying-glass.svg";
import Button from "lib/components/clickables/Button/Button";
import Checkbox from "lib/components/clickables/Checkbox";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Image from "lib/components/containers/Image/Image";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Input from "lib/components/forms/Input/Input";
import Select from "lib/components/forms/Select/Select";
import useCheckbox from "lib/hooks/useCheckbox";
import useOutsideClick from "lib/hooks/useOutsideClick";
import Calendar from "react-calendar";
import { useSearchStore } from "store/search";

import FilterCalendarRange from "./CalendarRange";
import FilterCheckedTopic from "./CheckedTopic";
import FilterCheckedType from "./CheckedType";
import FilterSelect from "./Select";
import FilterTopic from "./Topic";
import FilterType from "./Type";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface FilterProps {
  types: any;

  setTypes: React.Dispatch<React.SetStateAction<any[]>>;
  topics: any;
  setTopics: React.Dispatch<React.SetStateAction<any[]>>;
  topicList: any[];
  typeList: any[];
  setCalendar: Dispatch<SetStateAction<Value | undefined>>;
  calendar: Value | undefined;
  topic?: string;
  type?: string;
}

export const Filter: FC<FilterProps> = ({
  types,
  setTypes,
  topics,
  setTopics,
  topicList,
  typeList,
  setCalendar,
  calendar,
  topic,
  type,
}) => {
  const { search, setSearch } = useSearchStore();
  const [filterSettings, setFilterSettings] = useState(false);
  const [topicSettings, setTopicSettings] = useState(false);

  const typeSettingsRef = useRef(null);
  const typeSelectRef = useRef(null);

  const topicSettingsRef = useRef(null);
  const topicSelectRef = useRef(null);

  const calendarRef = useRef(null);
  const calendarSelectRef = useRef(null);

  const [checkedTopics, setCheckedTopics] = useState<any>([]);
  const [checkedTypes, setCheckedTypes] = useState<any>([]);
  const [showCalendar, setShowCalendar] = useState(false);
  useOutsideClick([typeSettingsRef, typeSelectRef], () => {
    setFilterSettings(false);
  });
  useOutsideClick([topicSettingsRef, topicSelectRef], () => {
    setTopicSettings(false);
  });
  useOutsideClick([calendarRef, calendarSelectRef], () => {
    setShowCalendar(false);
  });
  const showFilterSettings = () => {
    setFilterSettings(!filterSettings);
    setTopicSettings(false);
    setShowCalendar(false);
  };
  const showTopicSettings = () => {
    setTopicSettings(!topicSettings);
    setFilterSettings(false);
    setShowCalendar(false);
  };
  const showCalendarSettings = () => {
    setShowCalendar(!showCalendar);
    setTopicSettings(false);
    setFilterSettings(false);
  };

  return (
    <Box className={styles.filter} direction="column" gap={24}>
      <Box justifyContent="space-between" position="relative">
        <Box gap={10} alignment="left">
          <p className={styles.filterBy}>Filter By:</p>
          {!type && (
            <div ref={typeSelectRef}>
              <FilterSelect name="Type" handleClick={showFilterSettings} />
            </div>
          )}
          {!topic && (
            <div ref={topicSelectRef}>
              <FilterSelect name="Topic" handleClick={showTopicSettings} />
            </div>
          )}
          <div className={styles.container} ref={calendarSelectRef}>
            <FilterSelect
              name="Date Range"
              handleClick={showCalendarSettings}
            />

            {showCalendar && (
              <div className={styles.calendar} ref={calendarRef}>
                <Calendar
                  selectRange={true}
                  onChange={setCalendar}
                  value={calendar}
                />
              </div>
            )}
          </div>
        </Box>
        <Box position="relative">
          <Box
            sx={{ position: "absolute", left: "4px", bottom: "1px" }}
            height="100%"
            alignment="center"
          >
            <Image
              width={20}
              height={20}
              alt="?"
              src={Magnifying}
              sx={{
                width: "20px",
                height: "20px",
                marginRight: "-30px",
                zIndex: 9,
                position: "absolute",
              }}
            />
          </Box>
          <Box padding="0 20px 0 0" width="100%">
            <Input
              width={"300px"}
              placeholder="Search"
              padding="12px 8px 12px 38px"
              design="search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Box>
        </Box>
        {filterSettings && (
          <Box
            className={styles.filterSettings}
            alignment="center"
            direction="column"
            ref={typeSettingsRef}
          >
            <FilterType
              typeList={typeList}
              checkedTypes={checkedTypes}
              setCheckedTypes={setCheckedTypes}
            />
            <Button
              type="primary"
              onClick={() => {
                setFilterSettings(false);
                setTypes(checkedTypes);
              }}
              sx={{ width: "100%" }}
            >
              Apply
            </Button>
          </Box>
        )}

        {topicSettings && (
          <Box
            className={styles.topicSettings}
            direction="column"
            ref={topicSettingsRef}
            alignment="center"
          >
            <FilterTopic
              topicList={topicList}
              checkedTopics={checkedTopics}
              setCheckedTopics={setCheckedTopics}
            />
            <Button
              type="primary"
              onClick={() => {
                setTopicSettings(false);
                setTopics(checkedTopics);
                console.log(checkedTopics);
              }}
              sx={{ width: "100%" }}
            >
              Apply
            </Button>
          </Box>
        )}
      </Box>

      {(types.length > 0 ||
        topics.length > 0 ||
        (!(calendar instanceof Date) && Array.isArray(calendar))) && (
        <Container className={styles.filterList} alignment="left">
          {(types.length > 0 ||
            topics.length > 0 ||
            !(calendar instanceof Date)) && (
            <p className={styles.filterBy}>Selected: </p>
          )}
          {types.length > 0 && (
            <FilterCheckedType
              types={types}
              setTypes={setTypes}
              setCheckedTypes={setCheckedTypes}
            />
          )}
          {topics.length > 0 && (
            <FilterCheckedTopic
              topics={topics}
              setTopics={setTopics}
              setCheckedTopics={setCheckedTopics}
            />
          )}
          {!(calendar instanceof Date) && Array.isArray(calendar) && (
            <FilterCalendarRange
              calendar={calendar}
              setCalendar={setCalendar}
            />
          )}{" "}
        </Container>
      )}
    </Box>
  );
};
