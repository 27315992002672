import styles from "./SubPage.module.scss";

import { FC } from "react";

import SubPageIcon from "/assets/icons/sub_page.svg";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Image from "lib/components/containers/Image/Image";


interface SubPageProps {
  page: string;
  subPage: string;
}

export const SubPage: FC<SubPageProps> = ({ page, subPage }) => {
  return (
    <a href={`#${page}-${subPage}`}>
      <Wrapper className={styles.subPage}>
      <Image width={24} height={24} alt="?" src={SubPageIcon} />

        {subPage}
      </Wrapper>
    </a>
  );
};
