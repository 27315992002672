import styles from "./Content.module.scss";

import { FC } from "react";

import ArrowRight from "assets/icons/arrow_right_thin.svg";
import ShareButton from "components/ui/Share";
import { baseUrl } from "credentials/url";
import parseStringToHTML from "html-react-parser";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Image from "lib/components/containers/Image/Image";
import { marked } from "marked";
import { usePathnameStore } from "store/pathname";
import { overrideLink } from "utils/overrideLink";

interface ContentProps {
  title: string;
  industryCategory: string;
  publishedOn: string;
  imageUrl: string;
  content: string;
  subPages: any[];
}

export const Content: FC<ContentProps> = ({
  title,
  industryCategory,
  publishedOn,
  imageUrl,
  content,
  subPages,
}) => {const { setPathname } = usePathnameStore();

const handleLinkClick = (e: any, link: string) => {
  overrideLink(e, link);

  setPathname(link);
};
  return (
    <Container className={styles.specialtyGuide} direction="column">
      <Container
        className={styles.articleContainer}
        direction="column"
        id={title}
      >
        <Box className={styles.navigation} alignment="left">
          <a href="/" className={styles.link} onClick={(e) => {
              handleLinkClick(e, "/");
            }}
            onAuxClick={(e) => {
              handleLinkClick(e, "/");
            }}
            onContextMenu={(e) => {
              handleLinkClick(e, "/");
            }}>
            Home
          </a>{" "}
          <Image width={10} height={10} alt="?" src={ArrowRight} /> {title}
        </Box>

        <div className={styles.article}>
          <p className={styles.topic}>{industryCategory}</p>
          <h1 className={styles.heading}>{title}</h1>
          <h2 className={styles.description}>
            {/* A once-in-a-generation technology surges into a centuries-old
            industry. */}
          </h2>
          <p className={styles.createdBy}>
            {/* By John Doe, Jane Doe, Chris Jenkin, Mark Lang, and Dmitry Kapur */}
          </p>
          <Box alignment="left" className={styles.dateContainer}>
            <p className={styles.date}>
              {new Date(publishedOn).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
            </p>
            <div className={styles.circle}></div>
            <p className={styles.date}>6 min read</p>
          </Box>

          <ShareButton />

          {imageUrl ? (
            <Image
              height={450}
              alt="?"
              src={imageUrl}
              className={styles.image}
            />
          ) : (
            <Image
              height={450}
              alt="?"
              src={`${baseUrl}/assets/images/image@2x.png`}
              className={styles.image}
            />
          )}

          <Box className={styles.content} direction="column">
            <div className={styles.text}>
              {content && parseStringToHTML(marked.parse(content) as string)}
            </div>
            {subPages?.map((subPage) => (
              <div id={title + "-" + subPage.title} className={styles.subPage}>
                {subPage.imageUrl ? (
                  <Image
                    height={450}
                    alt="?"
                    src={`${imageUrl}`}
                    className={styles.image}
                  />
                ) : (
                  <Image
                    height={450}
                    alt="?"
                    src={`${baseUrl}/assets/images/image@2x.png`}
                    className={styles.image}
                  />
                )}
                <h2 className={styles.subHeading}>{subPage.title}</h2>
                <div className={styles.text}>
                  {subPage.content &&
                    parseStringToHTML(marked.parse(subPage.content) as string)}
                </div>
              </div>
            ))}
          </Box>
        </div>
      </Container>
    </Container>
  );
};
