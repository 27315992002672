export const overrideLink = (event: any, link: string) => {
  event.preventDefault();

  console.log("override");
  if (event.button === 1 || event.button === 2) {
    // Open the link in a new tab
    window.open(`/${hasValidKeywordInPath()}${link}`, "_blank");
  } else {
    console.log(`/${hasValidKeywordInPath()}${link}`);
    history.pushState({}, "", `/${hasValidKeywordInPath()}${link}`);
  }
};

const hasValidKeywordInPath = () => {
  let pathSegments = window.location.pathname.split("/").filter(Boolean);
  const index = pathSegments.findIndex((pathSegment) =>
    validKeywords.includes(pathSegment),
  );
  console.log(pathSegments.slice(0, index).join("/"));
  if (index == -1) {
    return pathSegments.join("/");
  } else return pathSegments.slice(0, index).join("/");
};

const validKeywords = [
  "article",
  "event",
  "specialty-guide",
  "webinar",
  "report",
];
