import styles from "./Card.module.scss";

import { FC } from "react";

import Box from "lib/components/containers/Box/Box";
import Image from "lib/components/containers/Image/Image";
import { Article } from "pages/Home/types/article";
import { useArticleStore } from "store/article";
import { usePageStore } from "store/page";
import { usePathnameStore } from "store/pathname";
import { formatRoute } from "utils/formatRoute";
import { overrideLink } from "utils/overrideLink";

import Placeholder from "/assets/images/image@2x.png";
import ArticleImage from "/assets/images/weaving-fairness@2x.png";

interface CardProps {
  article: Article;
}

export const Card: FC<CardProps> = ({ article }) => {
  const { setPage } = usePageStore();
  const { setArticle } = useArticleStore();
  let link = `/article/${formatRoute(article.industryCategory)}/${formatRoute(article.service)}/${formatRoute(article.title)}`;

  if (article.type == "specialty_guides")
    link = `/specialty-guide/${formatRoute(article.title)}`;
  if (article.type == "articles")
    link = `/article/${formatRoute(article.industryCategory)}/${formatRoute(article.title)}`;
  if (article.type == "events") link = `/event/${formatRoute(article.title)}`;

  const { setPathname } = usePathnameStore();

  const handleLinkClick = (e: any) => {
    overrideLink(e, link);
    setPathname(link);
  };
  return (
    <div
      className={styles.card}
      onClick={() => {
        setArticle(article);
      }}
    >
      <a
        href={link}
        style={{ width: "100%" }}
        onClick={(e) => {
          handleLinkClick(e);
        }}
        onAuxClick={(e) => {
          handleLinkClick(e);
        }}
        onContextMenu={(e) => {
          handleLinkClick(e);
        }}
      >
        {" "}
        <Image
          height={300}
          alt="?"
          src={article.imageUrl}
          className={styles.image}
        />
        <Box className={styles.content} direction="column">
          <p className={styles.topic}>{article.industryCategory}</p>
          <h4 className={styles.heading}>{article.title}</h4>
          <p className={styles.text}>{article.description}</p>
          <Box alignment="left" gap={10}>
            <p className={styles.date}>{article.publishedOn}</p>
            <div className={styles.circle}></div>
            <p className={styles.readMore}>Read More</p>
          </Box>
        </Box>
      </a>
    </div>
  );
};
