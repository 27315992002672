import "./index.css";

import { StrictMode } from "react";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import App from "./App.tsx";

createRoot(document.getElementById("gstream-plugin")!).render(
  //<StrictMode>
  <>
    <App />
    <ToastContainer
      position="top-right"
      theme="light"
      autoClose={5000}
      closeOnClick
    />
  </>,
  //</StrictMode>,
);
