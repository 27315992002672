import styles from "./Request.module.scss";

import { FC } from "react";

import Button from "lib/components/clickables/Button/Button";
import Box from "lib/components/containers/Box/Box";

interface RequestProps {}

export const Request: FC<RequestProps> = ({}) => {
  return (
    <Box
      direction="column"
      className={styles.request}
      alignItems="space-between"
    >
      <div className={styles.content}>
        <h4 className={styles.title}>Looking for something special?</h4>
        <p className={styles.description}>
          Tell us what you want to know and we will email you a link to your
          article once we have it published. Less than 24 hours.
        </p>
      </div>
      <Box className={styles.buttonContainer}>
        <Button type="primary" sx={{ width: "100%" }}>
          Send Request
        </Button>
      </Box>
    </Box>
  );
};
