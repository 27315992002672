import { create } from "zustand";

export type ModalType =
  | "none"
  | "share";
interface State {
  modal: ModalType;
  setModal: (modal: ModalType) => void;
  modalData: any;
  setModalData: (modalData: any) => void;
}

const useModalStore = create<State>((set) => ({
  modal: "none",
  setModal: (modal) =>
    set({
      modal: modal,
    }),
  modalData: {},
  setModalData: (modalData) => set({ modalData: modalData }),
  func:()=>{}
}));

export { useModalStore };
