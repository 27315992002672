import { Article } from "pages/Home/types/article";
// import { getArticle } from "utils/localStorage";
import { create } from "zustand";

export interface State {
  article: Article;
  setArticle: (article: Article) => void;
}

const useArticleStore = create<State>((set) => ({
  article: {
    id: "",
    content: "",
    description: "",
    imageUrl: "",
    industryCategory: "",
    publishedOn: "",
    createdBy: "",
    title: "",
    type: "",
    service: "",
    url: "",
    companyName: "",
    clientAddress: "",
    clientPhoneNumber: ""
  },
  setArticle: (article) =>
    set({
      article,
    }),
}));

export { useArticleStore };
