import styles from "./Signup.module.scss";

import { FC } from "react";

import Button from "lib/components/clickables/Button/Button";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Input from "lib/components/forms/Input/Input";
import { useForm } from "lib/hooks/useForm";
import { Client } from "api/index";
import Image from "lib/components/containers/Image/Image";
import Google from "assets/icons/google.svg"
import Facebook from "assets/icons/facebook.svg"
import Apple from "assets/icons/apple.svg"

export interface SignupProps {
  changeState: () => void;
  handleContinue: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const Signup: FC<SignupProps> = ({
  changeState,
  handleContinue,
  email,
  setEmail,
}) => {
  return (
    <Container className={styles.signup} direction="column" alignment="center">
      <Box className={styles.form} direction="column" gap={6}>
        <p className={styles.heading}>Create a free account, or log in</p>
        <p className={styles.description}>
          Gain access to limited free articles, news alerts, select newsletters
          and some promotions.
        </p>
      </Box>
      <Box className={styles.form} direction="column">
        <Wrapper className={styles.inputWrapper}>
          <label className={styles.label}>Email Address</label>
          <Input
            placeholder="example@gmail.com"
            design="normal"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Wrapper>

        <Wrapper className={styles.inputWrapper}>
          <Button type="primary" onClick={handleContinue}>
            Continue
          </Button>
          <p className={styles.account}>
            Already have an account?{" "}
            <span className={styles.login} onClick={changeState}>
              Log In
            </span>
          </p>
        </Wrapper>

        <p className={`${styles.terms} ${styles.opacity}`}>
          We promise to never share your information or send you anything other
          than this awesome guide without your permission.
        </p>

        <Box className={styles.lineContainer} alignment="center">
          <div className={styles.line} />
          <p>or</p>
          <div className={styles.line} />
        </Box>
        <div className={styles.terms}>
          By continuing, you agree to the{" "}
          <span className={styles.underline}>Terms of Sale</span>,{" "}
          <span className={styles.underline}>Terms of Service</span>, and{" "}
          <span className={styles.underline}>Privacy Policy</span>.
        </div>

        <Button
          type="sign-up"
          onClick={async () => {
            try {
              const { redirectUrl } =
                await Client.StreamPluginClientAPI.google_login(
                  window.location.href
                );
              window.location.href = redirectUrl;
            } catch (e) {
              console.log(e);
            }
          }}
        >
          <Image
            width={24}
            height={24}
            alt="?"
            src={Google}
            className={styles.buttonIcon}
          />
          Sign up with Google
        </Button>
        <Button type="sign-up">
          <Image
            width={24}
            height={24}
            alt="?"
            src={Apple}
            className={styles.buttonIcon}
          />
          Sign up with Apple
        </Button>
        <Button
          type="sign-up"
          onClick={async () => {
            try {
              const { redirectUrl } =
                await Client.StreamPluginClientAPI.facebook_login(
                  window.location.href
                );
              window.location.href = redirectUrl;
            } catch (e) {
              console.log(e);
            }
          }}
        >
          <Image
            width={24}
            height={24}
            alt="?"
            src={Facebook}
            className={styles.buttonIcon}
          />
          Sign up with Facebook
        </Button>
      </Box>
    </Container>
  );
};

export default Signup;
