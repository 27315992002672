"use client";

import styles from "./SpecialtyGuide.module.scss";

import { FC, useEffect, useRef, useState } from "react";

import { Client } from "api/index";
import ArrowRight from "assets/icons/arrow-right.svg";
import Authorization from "components/ui/Authorization/Authorization";
import CheckEmail from "components/ui/Authorization/CheckEmail/CheckEmail.lazy";
import CreatePassword from "components/ui/Authorization/CreatePassword/CreatePassword.lazy";
import Login from "components/ui/Authorization/Login/Login.lazy";
import { useAuth } from "hooks/useAuth";
import parseStringToHTML from "html-react-parser";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Image from "lib/components/containers/Image/Image";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import LoadingSpinner from "lib/components/other/LoadingSpinner/LoadingSpinner";
import useOutsideClick from "lib/hooks/useOutsideClick";
import { marked } from "marked";
import { ToastContainer } from "react-toastify";
import { useApiKeyStore } from "store/apiKeyStore";
import { useArticleStore } from "store/article";
import { useAuthStore } from "store/auth";

import SpecialtyGuideContent from "./Content";
import SpecialtyGuideNavigation from "./Navigation";
import Placeholder from "/assets/images/section-hero@2x.jpg";
import MetaTags from "components/layout/MetaTags";

export interface SpecialtyGuideProps {
  guideProp: any;
}

const SpecialtyGuide: FC<SpecialtyGuideProps> = ({ guideProp }) => {
  const {
    authorized,
    showAuthorization,
    setShowAuthorization,
    checkingAuthorization,
  } = useAuthStore();
  const { article } = useArticleStore();
  const { apiKey } = useApiKeyStore();
  const [guide, setGuide] = useState<any>(guideProp);
  const [page, setPage] = useState<any>(guideProp);
  const [loading, setLoading] = useState(false);
  const topRef = useRef<any>(null);

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "instant" });
  };

  const getPageIndex = () => {
    return guide?.pages?.indexOf(page);
  };

  useEffect(() => {
    const getContent = async () => {
      setLoading(true);

      const guide = await Client.StreamPluginClientAPI.guideContent(
        guideProp.guideId,
      );
      guide.pages = guide.content;
      delete guide.content;
      setGuide(guide);
      setPage(guide.pages[0]);
      setLoading(false);
    };
    if (guideProp && authorized) getContent();
  }, [authorized]);

  useEffect(() => {
    if (guideProp) {
      if (authorized) {
        setShowAuthorization(false);
      } else {
        setShowAuthorization(true);
      }
    }
  }, [authorized]);

  useAuth();

  return (
    <>
      {" "}
      <MetaTags meta={guideProp} />
      <Container className={styles.background} ref={topRef}>
        <Box className={styles.content} direction="row" alignment="top">
          {guide?.pages && (
            <SpecialtyGuideNavigation pages={guide.pages} setPage={setPage} />
          )}
          {page && !loading && !checkingAuthorization ? (
            <Box direction="column" flex="1">
              {guide && page && (
                <SpecialtyGuideContent
                  industryCategory={guide.industryCategory}
                  content={page.content}
                  // description={description}
                  imageUrl={page.imageUrl}
                  publishedOn={page.publishedOn || guide.publishedOn}
                  title={page.title}
                  subPages={page.subPages}
                />
              )}
              {guide?.pages?.length > 0 && (
                <Box className={styles.chapterNavigation} alignment="right">
                  <Wrapper
                    className={`${styles.arrowLeft} ${getPageIndex() == 0 && styles.fade}`}
                    onClick={() => {
                      if (getPageIndex() > 0) {
                        setPage(guide.pages[getPageIndex() - 1]);
                        scrollToTop();
                      }
                    }}
                  >
                    <Image width={16} height={16} alt="?" src={ArrowRight} />
                  </Wrapper>
                  <p className={` ${getPageIndex() == 0 && styles.fade}`}>
                    Previous Chapter
                  </p>{" "}
                  <p>|</p>{" "}
                  <p
                    className={` ${getPageIndex() == guide.pages.length - 1 && styles.fade}`}
                  >
                    Next Chapter
                  </p>
                  <Wrapper
                    className={`${styles.arrow} ${getPageIndex() == guide.pages.length - 1 && styles.fade}`}
                    onClick={() => {
                      if (getPageIndex() < guide.pages.length - 1) {
                        setPage(guide.pages[getPageIndex() + 1]);
                        scrollToTop();
                      }
                    }}
                  >
                    <Image width={16} height={16} alt="?" src={ArrowRight} />
                  </Wrapper>
                </Box>
              )}
            </Box>
          ) : (
            <LoadingSpinner />
          )}
        </Box>
      </Container>
      <Authorization />
      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={5000}
        closeOnClick
      />
    </>
  );
};

export default SpecialtyGuide;
