import styles from "./Topic.module.scss";

import { FC, useEffect } from "react";

import Checkbox from "lib/components/clickables/Checkbox";
import Box from "lib/components/containers/Box/Box";

interface TopicProps {
  topicList: any[];
  checkedTopics: string[];
  setCheckedTopics: React.Dispatch<any>;
}

export const Topic: FC<TopicProps> = ({
  topicList,
  checkedTopics,
  setCheckedTopics,
}) => {
  const n = topicList.length; // Total number of elements
  const rows = n / 2; // Number of rows
  const cols = 2; // Number of columns

  
 
  // Convert the array into an (n/2)x2 matrix
  const topicRes = [];
  for (let i = 0; i < rows; i++) {
    topicRes.push(topicList.slice(i * cols, i * cols + cols));
  }

  const handleCheckboxChange = (event: any) => {
    const { checked, value } = event.target;
    setCheckedTopics((prev: any) =>
      checked ? [...prev, value] : prev.filter((v: any) => v !== value)
    );
  };
  return (
    <Box direction="row" alignment="top-left" width={'100%'} >
      {topicRes.map((topic, index) => (
        <Box className={styles.checkbox} key={`${topic[0]} - ${topic[1]}`} alignment="top-left" direction="column">
          <Checkbox 
            text={topic[0]}
            handleChange={handleCheckboxChange}
            checked={checkedTopics.includes(topic[0])}
            sx={{ marginBottom: "16px" }}
            key={topic[0]}
          />
          {topic[1] && (
            <Checkbox
              text={topic[1]}
              handleChange={handleCheckboxChange}
              checked={checkedTopics.includes(topic[1])}
              key={topic[1]}
            
            />
          )}
        </Box>
      ))}
    </Box>
  );
};
