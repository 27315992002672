import styles from "./Navigation.module.scss";

import { FC } from "react";

import Box from "lib/components/containers/Box/Box";

import SpecialtyGuideNavigationPage from "./Page";

interface NavigationProps {
  pages: any;
  setPage: React.Dispatch<any>;
}

export const Navigation: FC<NavigationProps> = ({ pages, setPage }) => {
  return (
    <div className={styles.navigation}>
      <div className={styles.heading}></div>
      {pages?.map((page: any) => <SpecialtyGuideNavigationPage page={page} setPage={setPage}/>)}
    </div>
  );
};
