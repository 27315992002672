import { startTransition, useEffect } from "react";

import { Body_get_access_token_api_v1_stream_plugin_get_access_token_post } from "api/api";
import { Client } from "api/index";
import { useApiKeyStore } from "store/apiKeyStore";
import { useAuthStore } from "store/auth";
import { useModalStore } from "store/modal";
import { usePageStore } from "store/page";
import { usePathnameStore } from "store/pathname";

export const useAuth = () => {
  const { pathname, setPathname } = usePathnameStore();
  const removeQueryParams = () => {
    // Build the dynamic path by substituting the params
    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);

    // Remove the 'user_id' parameter
    url.searchParams.delete("user_id");

    // Set the new URL without 'user_id'
    const newUrl = url.origin + url.pathname + url.search;

    //history.pushState({}, "", `${url.pathname}`);
    //setPathname(newUrl); // Navigate without query params
  };
  const { apiKey } = useApiKeyStore();
  const { setAuthorized, setCheckingAuthorization } = useAuthStore();

  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("user_id");
  console.log(userId);
  useEffect(() => {
    // fetch call
    const checkAuth = async () => {
      setCheckingAuthorization(true);
      const accessToken = localStorage.getItem("token");

      if (accessToken) {
        try {
          // const { accessToken } =
          //   await Client.StreamPluginClientAPI.refresh_token(apiKey);
          localStorage.setItem("token", accessToken);
          setAuthorized(true);
        } catch (e) {
          console.log(e);
          localStorage.removeItem("token");
          setAuthorized(false);
        }
      } else {
        setAuthorized(false);
      }
      setCheckingAuthorization(false);
    };
    checkAuth();

    return () => {
      removeQueryParams();
    };
  }, []); // Empty dependency array ensures useEffect runs once

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const { accessToken } =
          await Client.StreamPluginClientAPI.get_access_token(
            new Body_get_access_token_api_v1_stream_plugin_get_access_token_post(
              {
                user_id: userId as string,
              },
            ),
          );
        removeQueryParams();

        if (accessToken) {
          localStorage.setItem("token", accessToken);
          setAuthorized(true);
        }
      } catch (e) {
        removeQueryParams();
      }
    };
    if (userId) {
      getAccessToken();
    }
    return () => {
      removeQueryParams();
    };
  }, []);
};
