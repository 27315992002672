import { useEffect } from "react";

interface MetaTagsProps {
  meta: any;
}

export const MetaTags: React.FC<MetaTagsProps> = ({ meta }) => {
  const url = typeof window !== "undefined" ? window.location.href : "";
  
  
  const {
    h1,
    title,
    description,
    imageUrl,
    publishedOn,
    companyName,
    type,
    twitterImageUrl,
    facebookImageUrl,
  } = meta;
  let schemaData = {
    "@context": "https://schema.org",
    "@type": { type },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": { url },
    },
    headline: { title },
    description: { description },
    image: [{ url }],
    author: {
      "@type": "Person",
      name: { companyName },
    },
    publisher: {
      "@type": "Organization",
      name: { companyName },
      logo: {
        "@type": "ImageObject",
        url: "{{ PUBLISHER_LOGO_URL }}",
      },
    },
    datePublished: { publishedOn },
    dateModified: { publishedOn },
  };

  useEffect(() => {
    const setMetaTag = (
      name: string,
      value: string,
      property: "name" | "property" | "http-equiv" = "name",
    ) => {
      let meta = document.querySelector<HTMLMetaElement>(
        `meta[${property}="${name}"]`,
      );
      if (!meta) {
        meta = document.createElement("meta");
        meta.setAttribute(property, name);
        document.head.appendChild(meta);
      }
      meta.setAttribute("content", value);
      return meta;
    };

    const setLinkTag = (rel: string, href: string) => {
      let link = document.querySelector<HTMLLinkElement>(`link[rel="${rel}"]`);
      if (!link) {
        link = document.createElement("link");
        link.setAttribute("rel", rel);
        document.head.appendChild(link);
      }
      link.setAttribute("href", href);
      return link;
    };

    const setScriptTag = (id: string, type: string, content: string) => {
      let script = document.getElementById(id) as HTMLScriptElement | null;
      if (!script) {
        script = document.createElement("script");
        script.setAttribute("id", id);
        script.setAttribute("type", type);
        document.head.appendChild(script);
      }
      script.textContent = content;
      return script;
    };

    const addedMetaTags = [
      setMetaTag(
        "Content-Type",
        "text/html; charset=windows-1252",
        "http-equiv",
      ),
      setMetaTag("X-UA-Compatible", "IE=edge", "http-equiv"),
      setMetaTag(
        "robots",
        "follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large",
      ),
      setMetaTag("author", companyName),
      setMetaTag("description", description),
      setMetaTag("fb:app_id", "1133688368134711", "property"),
      setMetaTag("og:type", type, "property"),
      setMetaTag("og:title", title, "property"),
      setMetaTag("og:description", description, "property"),
      setMetaTag("og:url", url, "property"),
      setMetaTag("og:site_name", h1, "property"),
      setMetaTag("og:image", facebookImageUrl, "property"),
      setMetaTag("og:image:url", facebookImageUrl, "property"),
      setMetaTag("og:image:secure_url", facebookImageUrl, "property"),
      setMetaTag("og:image:width", "1280", "property"),
      setMetaTag("og:image:height", "850", "property"),
      setMetaTag("og:image:type", "image/jpeg", "property"),
      setMetaTag("og:image:alt", title, "property"),
      setMetaTag("twitter:card", twitterImageUrl),
      setMetaTag("twitter:description", title),
      setMetaTag("twitter:title", title),
      setMetaTag("twitter:image", twitterImageUrl),
    ];

    const canonicalLink = setLinkTag("canonical", url);
    const jsonLdScript = setScriptTag(
      "json-ld-script",
      "application/ld+json",
      JSON.stringify(schemaData),
    );

    document.title = title;

    return () => {
      addedMetaTags.forEach((meta) => meta?.remove());
      canonicalLink?.remove();
      jsonLdScript?.remove();
    };
  }, [
    title,
    url,
    companyName,
    twitterImageUrl,
    type,
    description,
    h1,
    facebookImageUrl,
    schemaData,
  ]);

  return null;
};

