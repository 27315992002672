import { create } from "zustand";

interface State {
  user: any | null;
  setUser: (user: State["user"]) => void;

  authorized: any | null;
  setAuthorized: (authorized: State["authorized"]) => void;

  showAuthorization: boolean;
  setShowAuthorization: (showAuthorization: State["showAuthorization"]) => void;

  state: string;
  setState: (state: State["state"]) => void;

  checkingAuthorization: boolean;
  setCheckingAuthorization: (
    authorized: State["checkingAuthorization"],
  ) => void;
}

const useAuthStore = create<State>((set) => ({
  user: null,
  setUser: (user: State["user"]) => set({ user }),

  authorized: null,
  setAuthorized: (authorized: State["authorized"]) => set({ authorized }),

  showAuthorization: false,
  setShowAuthorization: (showAuthorization: State["showAuthorization"]) =>
    set({ showAuthorization }),

  state: "signup",
  setState: (state: State["state"]) =>
    set({ state }),

  checkingAuthorization: true,
  setCheckingAuthorization: (checkingAuthorization: State["checkingAuthorization"]) => set({ checkingAuthorization }),
}));

export { useAuthStore };

//state and postal code city street
//debounce
