import React, { Suspense, lazy } from "react";

import { WebinarProps } from "./Webinar";

const LazyWebinar = lazy(() => import("./Webinar"));

const WebinarPage = (
  props: JSX.IntrinsicAttributes & WebinarProps & { children?: React.ReactNode }
) => (
  <Suspense fallback={null}>
    <LazyWebinar {...props} />
  </Suspense>
);

export default WebinarPage;
