import React, { Suspense, lazy } from "react";

import { EventsProps } from "./Events";

const LazyEvents = lazy(() => import("./Events"));

const EventsPage = (
  props: JSX.IntrinsicAttributes & EventsProps & { children?: React.ReactNode },
) => (
  <Suspense fallback={null}>
    <LazyEvents {...props} />
  </Suspense>
);

export default EventsPage;
