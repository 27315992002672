import styles from "./Article.module.scss";

import { FC, useEffect } from "react";

import Container from "lib/components/containers/Container/Container";
import { columnMap } from "pages/Home/constants/column";
import { Article as ArticleType } from "pages/Home/types/article";
import { Size } from "pages/Home/types/size";
import { useArticleStore } from "store/article";
import { usePageStore } from "store/page";
import { usePathnameStore } from "store/pathname";
import { formatRoute } from "utils/formatRoute";
import { overrideLink } from "utils/overrideLink";

import CardArticleLarge from "./Large/index";
import CardArticleMedium from "./Medium";
import CardArticleSmall from "./Small";

interface ArticleProps {
  article: ArticleType;

  size?: Size;
}

export const Article: FC<ArticleProps> = ({ article, size = "small" }) => {
  const columns = columnMap[size];
  const { setPage } = usePageStore();
  const { setArticle } = useArticleStore();
  const { setPathname } = usePathnameStore();

  let link = "/" + article?.url;
  if (article.type == "specialty_guides")
    link = `/specialty-guide/${formatRoute(article.title)}/`;
  if (article.type == "articles")
    link = `/article/${formatRoute(article.industryCategory)}/${formatRoute(article.service)}/${formatRoute(article.title)}/`;
  if (article.type == "events") link = `/event/${formatRoute(article.title)}/`;
  if (article.type == "reports")
    link = `/report/${formatRoute(article.title)}/`;
  if (article.type == "webinars")
    link = `/webinar/${formatRoute(article.title)}`;

  const handleLinkClick = (e: any, link: string) => {
    overrideLink(e, link);
    setArticle(article);
    setPathname(link);
  };

  return (
    <Container
      className={styles.article}
      alignment="top"
      direction="column"
      sx={{
        gridColumn: `span ${columns}`,
      }}
    >
      <a
        href={link}
        style={{ width: "100%" }}
        onClick={(e) => {
          handleLinkClick(e, link);
        }}
        onAuxClick={(e) => {
          handleLinkClick(e, link);
        }}
        onContextMenu={(e) => {
          handleLinkClick(e, link);
        }}
      >
        {size == "large" && <CardArticleLarge article={article} />}
        {size == "medium" && <CardArticleMedium article={article} />}
        {size == "small" && <CardArticleSmall article={article} />}
      </a>
    </Container>
  );
};
