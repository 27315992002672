import styles from "./CheckEmail.module.scss";

import { FC } from "react";

import Button from "lib/components/clickables/Button/Button";
import Box from "lib/components/containers/Box/Box";
import Container from "lib/components/containers/Container/Container";
import Wrapper from "lib/components/containers/Wrapper/Wrapper";
import Input from "lib/components/forms/Input/Input";
import { useForm } from "lib/hooks/useForm";

interface CheckEmailProps {}

const CheckEmail: FC<CheckEmailProps> = ({}) => {
  const { form, handleChange } = useForm({
    email: "",
    password: "",
  });
  return (
    <Container
      className={styles.checkEmail}
      direction="column"
      alignment="center"
    >
      <p className={styles.heading}>Check your email</p>
      <Box className={styles.form} direction="column">
        <p className={styles.text}>
          If there is an account associated with [companyName] linked to
          <span className={styles.email}> johndoe@gmail.com</span>, you will
          receive an email containing a link for automatic login. Please note
          that this link will expire in 15 minutes.
        </p>
        <p className={styles.text}>
          Your existing password still works, should you want to log in with it
          later.
        </p>

        <Button type="primary">Create Account</Button>

        <p className={styles.text}>
          Enjoy unlimited access to all of [companyNam] Articles.
        </p>
      </Box>
    </Container>
  );
};

export default CheckEmail;
