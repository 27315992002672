import styles from "./Welcome.module.scss";

import { FC } from "react";

import Box from "lib/components/containers/Box/Box";

interface WelcomeProps {
  heroSettings: {
    h1: string;
    heroImageUrl: string;
    introText: string;
    welcomeText: string;
  };
}

export const Welcome: FC<WelcomeProps> = ({ heroSettings }) => {
  return (
    <Box className={styles.welcome} alignment="center">
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>{heroSettings.introText}</h2>
      </div>
      <p className={styles.content}>{heroSettings.welcomeText}</p>
    </Box>
  );
};
