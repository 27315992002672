import styles from "./Checkbox.module.scss";

import { CSSProperties, FC } from "react";

import Text from "lib/components/typography/Text/Text";

interface CheckboxProps {
  text?: string;
  checked?: boolean;
  handleChange?: (e?: any) => void;
  sx?: CSSProperties;
}

export const Checkbox: FC<CheckboxProps> = ({
  text,
  checked,
  sx,
  handleChange = () => {},
}) => {
  return (
    <label className={styles.customCheckbox} style={{ ...sx }}>
      <input
        type="checkbox"
        value={text}
        checked={checked}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <span className={styles.checkmark}></span>
      {text && <Text sx={{ marginLeft: "10px" }}>{text}</Text>}
    </label>
  );
};
