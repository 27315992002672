import { RefObject, useEffect } from "react";

const useOutsideClick = <T extends HTMLElement>(
  refs: RefObject<T> | RefObject<T>[], // Accepts single or multiple refs
  callback: () => void
): void => {
  useEffect(() => {
    const refArray = Array.isArray(refs) ? refs : [refs]; // Normalize to array

    function handleClickOutside(event: MouseEvent) {
      if (refArray.some(ref => ref.current && ref.current.contains(event.target as Node))) {
        return; // Click inside, do nothing
      }
      callback(); // Click outside, trigger callback
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, callback]);
};

export default useOutsideClick;
